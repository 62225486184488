import React from "react";
import {RiMoonLine, RiSunLine} from "react-icons/ri";
import Logic from "./Logic";
import {NavLink, useLocation} from "react-router-dom";
import useWindowDimensions from "../../components/getWindowDimensions"

export const SwitchTheme = (props) => {
    const {toggle, toggleDarkTheme} = Logic();
    const location = useLocation()
    // color svg theme
    let contourTheme = '#ffffff'
    let strokeTheme = '#ffffff'

    // darth and light svg theme
    if (toggle) {
        strokeTheme = '#355dff'
        contourTheme = '#1d2839'
    } else {
        contourTheme = '#fbfcfd'
        strokeTheme = '#355dff'
    }
    const LightTheme = [{
        id: 1,
        Icon: RiSunLine,
        label: "Light theme",
        path: "/login",
    }]
    const DarkTheme = [{
        id: 2,
        Icon: RiMoonLine,
        label: "Dark theme",
        path: "/login",
    }]
    return (
        <div
            onClick={toggleDarkTheme}
            className="theme-toggle">
            {toggle
                ?
                <div className="container-svg">
                    {/*<svg viewBox="0 0 32 32" width="24px"*/}
                    {/*	 xmlns="http://www.w3.org/2000/svg">*/}
                    {/*	/!*<path fill={contourTheme} stroke={strokeTheme}*!/*/}
                    {/*	<path fill="currentColor" stroke="currentColor"*/}
                    {/*		  d="M16 23.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM16 4.5v-1M7.868 7.868l-.707-.707M4.5 16h-1M7.868 24.132l-.707.707M16 27.5v1M24.132 24.132l.707.707M27.5 16h1M24.132 7.868l.707-.707"*/}
                    {/*		  strokeWidth="2" strokeLinecap="round"*/}
                    {/*		  strokeLinejoin="round">*/}
                    {/*	</path>*/}
                    {/*</svg>*/}
                    {LightTheme.map((item) => {
                        const {id, Icon, label, path} = item;
                        return (
                            <span className="sidebar_item" key={id}>
                                <div className="sidebar_icon">
                                    <Icon className="icon"/>
                                </div>
                                <span className="sidebar_text">{label}</span>
                            </span>
                        );
                    })}
                    {/*<span>Light theme</span>*/}
                </div>
                :
                <div className="container-svg">
                    {/*<svg viewBox="0 0 32 32" width="24px"*/}
                    {/*     xmlns="http://www.w3.org/2000/svg"*/}
                    {/*     className="w-6">*/}
                    {/*    /!*<path fill={contourTheme} stroke={strokeTheme}*!/*/}
                    {/*    <path fill="currentColor" stroke="currentColor"*/}
                    {/*          d="M27.083 19.081A11.504 11.504 0 0112.919 4.917h0a11.502 11.502 0 1014.164 14.164h0z"*/}
                    {/*          strokeWidth="2" strokeLinecap="round"*/}
                    {/*          strokeLinejoin="round">*/}
                    {/*    </path>*/}
                    {/*</svg>*/}
                    {/*<span>Dark theme</span>*/}
                    {DarkTheme.map((item) => {
                        const {id, Icon, label, path} = item;
                        return (
                            <span className="sidebar_item" key={id}>
                                <div className="sidebar_icon">
                                    <Icon className="icon"/>
                                </div>
                                <span className="sidebar_text">{label}</span>
                            </span>
                        );
                    })}
                </div>
            }
        </div>
    );
};
