import React from 'react';
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "../../components/payment/CheckoutForm";
import {loadStripe} from "@stripe/stripe-js";
import {Layout} from "../../components/Layout";

const Payment = () => {
    // const stripePromise = loadStripe('pk_live_51KGLmFEmPG9DG1lY4Ql6J6OYrPDtIaO6uMP9YBypEUI23aJXuz3Wr3i6GVWNlrzn2uATciYAhLu1JG50LmT6mYMw002LJb6f5W');
    const stripePromise = loadStripe('pk_test_51KGLmFEmPG9DG1lYyCBgYbO49CmppzAyPuv1DRjsGVJUaDiT1ISaHKQmkNmuMQS0DSHkSBHvi6VCt7LoCjSnOOrM007pyW4y9l');

    return (
        <Layout>
            <Elements stripe={stripePromise}>
                <CheckoutForm />
            </Elements>
        </Layout>
    );
};

export default Payment;