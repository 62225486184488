import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {clearProducts, getOneRegionProduct} from "../../redux/product/productAction";


const QueryData = () => {
    const dispatch = useDispatch()
    const {regions} = useSelector((state => state.region))

    const {categories} = useSelector((state => state.category))

    useEffect(() => {
                if (regions[0] !== '0-0') {

            regions.find(region => {
                if (region.length === 5) {
                    categories.map(category => {
                        dispatch(getOneRegionProduct(region, category))
                    })
                }
                // // --------- '0-0-1-....' ---------
                if (region.length > 5 && region[4] === '1') {
                    categories.map(category => {
                        dispatch(getOneRegionProduct('0-0-1', category))
                    })
                }
                // --------- '0-0-2-....' ---------
                if (region.length > 5 && region[4] === '2') {
                    categories.map(category => {
                        dispatch(getOneRegionProduct('0-0-2', category))
                    })
                }

            })
        }
        // --------- '0-0' ---------
        if (regions.length !== 0 && regions[0] === '0-0') {
            let regionsAll = ['0-0-0', '0-0-1', '0-0-2']
            regionsAll.map(region => {


                if (categories.length !== 0) {
                    categories.map(category => {

                        dispatch(getOneRegionProduct(region, category))
                    })
                }
            })
        }

        dispatch(clearProducts())
    }, [regions, categories])


    return (
        <></>
    )
};

export default QueryData;