import {ADD_CART_COST, ADD_CART_USER} from "./type";

const initialState = {
	user: '',
	cost: 0
}
const reducerCart = (state = initialState, action) => {

	switch (action.type) {
		case ADD_CART_USER:
			const user = action.payload
			return {
				...state,
				user: user
			}
		case ADD_CART_COST:
			const cost = action.payload
			return {
				...state,
				cost: cost
			}
		default:
			return state;
	}
};

export default reducerCart;
