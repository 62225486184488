import {SET_PHONE, FALSE_PHONE} from "./type";

const initialState = {
	phoneActive: false
}
const phoneReducer = (state = initialState, action) => {

	switch (action.type) {
		case SET_PHONE:
			return {
				...state,
				phoneActive: action.payload
			}
		case FALSE_PHONE:
			return {
				...state,
				phoneActive: false
			}
		default:
			return state;
	}
};

export default phoneReducer;
