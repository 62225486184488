import {SET_EMAIL, FALSE_EMAIL} from "./type";

export const setEmail = (payload) => {
    return {
        type: SET_EMAIL,
        payload: payload
    };
};
export const falseEmail = () => {
    return {
        type: FALSE_EMAIL,
        payload: false
    };
};
