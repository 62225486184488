import {
    REGION_LOADING,
    GET_REGION,
    CLEAR_REGION,
    ADD_TO_TEMP_FLEMISH,
    REMOVE_TEMP_FLEMISH,
    ADD_TO_TEMP_WALLOON,
    REMOVE_TEMP_WALLOON
} from './types'

export const getRegion = (payload) => async dispatch => {
    dispatch({
        type: GET_REGION,
        payload: payload
    })
}

export const addToTempFlemish = () => dispatch => {
    dispatch({
        type: ADD_TO_TEMP_FLEMISH,
    })
}
export const removeTempFlemish = () => dispatch => {
    dispatch({
        type: REMOVE_TEMP_FLEMISH,
    })
}

export const addToTempWalloon = () => dispatch => {
    dispatch({
        type: ADD_TO_TEMP_WALLOON,
    })
}
export const removeTempWalloon = () => dispatch => {
    dispatch({
        type: REMOVE_TEMP_WALLOON,
    })
}
export const clearRegion = () => dispatch => {
    dispatch({
        type: CLEAR_REGION
    })
}

