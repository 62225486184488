import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./routes/home";
import CustomScraper from "./routes/custom-scraper";
import 'antd/dist/antd.css';
import Profile from "./routes/profile";
import {useAuth0} from "@auth0/auth0-react";
import Test from "./routes/test";
import AddUser from "./components/sidebar/addUser";
import Payment from "./routes/payment";

const App = () => {
    const {isAuthenticated, getAccessTokenSilently} = useAuth0();

    const callSecureApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            localStorage.setItem('jwt', token)
            // console.log(localStorage.getItem('jwt'))
        } catch (error) {

            console.log(error.message)
        }
    };
    callSecureApi()
    // AddUser()
    return (
            <Switch>

                <Route
                    exact
                    path="/"
                    component={(props) => <Home />}
                />
                {isAuthenticated &&
                <Route
                    exact
                    path="/custom-scraper"
                    component={(props) => <CustomScraper {...props} />}
                />
                }

                {isAuthenticated &&
                <Route
                    exact
                    path="/profile"
                    component={(props) => <Profile/>}
                />
                }

                {isAuthenticated &&
                <Route
                    exact
                    path="/test"
                    component={(props) => <Test/>}
                />
                }

                {isAuthenticated &&
                <Route
                    exact
                    path="/payment"
                    component={(props) => <Payment/>}
                />
                }

                <Redirect from="/test" to="/test" />
            </Switch>
    );
};

export default App
// pk_live_51KGLmFEmPG9DG1lY4Ql6J6OYrPDtIaO6uMP9YBypEUI23aJXuz3Wr3i6GVWNlrzn2uATciYAhLu1JG50LmT6mYMw002LJb6f5W