import styled from "styled-components";


export const HeroBg = styled.div`
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
width: 100%;
height: 100%;
overflow: hidden;
// z-index: 0;
// opacity: 1;
// transition: opacity 5s;
`;

export const VideoBg = styled.video`
width: 100%;
height: 100%;
-o-object-fit: cover; //research
object-fit: cover;
background: #232a34; //very dk gray with hint of blue 
// opacity: 1;
// transition: opacity 5s;
`;