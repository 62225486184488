import {SET_ADDRESS, FALSE_ADDRESS} from "./type";

export const setAddress = (payload) => {
    return {
        type: SET_ADDRESS,
        payload: payload
    };
};
export const falseAddress = () => {
    return {
        type: FALSE_ADDRESS,
        payload: false
    };
};
