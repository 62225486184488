import {SET_WEBSITE, FALSE_WEBSITE} from "./type";

const initialState = {
	websiteActive: false
}
const websiteReducer = (state = initialState, action) => {

	switch (action.type) {
		case SET_WEBSITE:
			return {
				...state,
				websiteActive: action.payload
			}
		case FALSE_WEBSITE:
			return {
				...state,
				websiteActive: false
			}
		default:
			return state;
	}
};

export default websiteReducer;
