import {TreeSelect} from 'antd';
import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom"
import {useDispatch} from "react-redux";
import {clearRegion, getRegion} from "../../redux/region/regionAction";


const {SHOW_PARENT} = TreeSelect;

const treeData = [
    {
        title: 'All regions',
        key: '0-0',
        children: [
            {
                title: 'Brussels Capital Region',
                key: '0-0-0',

            },
            {
                title: 'Flemish Region',
                key: '0-0-1',
                children: [
                    {
                        active: false,
                        title: "Antwerp Province",
                        key: "0-0-1-0"
                    },
                    {
                        active: false,
                        title: "Limburg Province",
                        key: "0-0-1-1"
                    },
                    {
                        active: false,
                        title: "East Flanders Province",
                        key: "0-0-1-2"
                    },
                    {
                        active: false,
                        title: "Flemish Brabant Province",
                        key: "0-0-1-3"
                    },
                    {
                        active: false,
                        title: "West Flanders Province",
                        key: "0-0-1-4"
                    },
                ],
            },
            {
                title: 'Walloon Region',
                key: '0-0-2',
                children: [
                    {
                        active: false,
                        title: "Hainaut Province",
                        key: "0-0-2-0"
                    },
                    {
                        active: false,
                        title: "Liège Province",
                        key: "0-0-2-1"
                    },
                    {
                        active: false,
                        title: "Luxembourg Province",
                        key: "0-0-2-2"
                    },
                    {
                        active: false,
                        title: "Namur Province",
                        key: "0-0-2-3"
                    },
                    {
                        active: false,
                        title: "Walloon Brabant Province",
                        key: "0-0-2-4"
                    },

                ],
            },
        ],
    },
];

function RegionTreeSelectFunction() {
    const [state, setState] = useState([])
    const dispatch = useDispatch()

    const onChange = (value) => {
        setState(value);
    };

    // --------- get region ---------
    useEffect(() => {
        dispatch(getRegion(state))
    }, [state])

    const location = useLocation()
    useEffect(() => {
        if (location.path !== '/'){
            dispatch(clearRegion())
        }
    }, [location.path])


    const tProps = {
        treeData,
        value: state,
        onChange: onChange,
        treeCheckable: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: 'select region',
        style: {
            width: '100%',
        },
        treeDefaultExpandedKeys: ['0-0'],
        showSearch: true,
        treeNodeFilterProp: 'title'
    };
    return <TreeSelect {...tProps} />;

}

export default RegionTreeSelectFunction