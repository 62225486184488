import axios from 'axios'

// const API = axios.create({ baseURL: 'http://localhost:7000/api/'})
const API = axios.create({ baseURL: 'https://back.rapiddb.biz/api/'})

API.interceptors.request.use((req) => {
    if(localStorage.getItem('jwt')) {
        req.headers.authorization = `Bearer ${localStorage.getItem("jwt")}`
    }
    return req
})

export const getAllProducts = () => API.get('/all-region')
export const getOneRegionProducts = (region) => API.get(`/all-region/${region}`)
export const getOneRegionProduct = (region, product) => API.get(`/all-region/${region}/${product}`)
// export const addProduct = (newProduct) => API.post('/product', newProduct)
// export const updateProduct = (updatedProduct) => API.put(`/products/${updatedProduct._id}`, updatedProduct)
// export const deleteProduct = (id) => API.delete(`/products/${id}`)