import {
    REGION_LOADING,
    GET_REGION,
    CLEAR_REGION,
    ADD_TO_TEMP_FLEMISH,
    REMOVE_TEMP_FLEMISH,
    ADD_TO_TEMP_WALLOON,
    REMOVE_TEMP_WALLOON
} from './types'

const initialState = {
    regions: [],
    isLoading: false,
    flemish: false,
    walloon: false,
}

export default function regionReducer(state = initialState, action) {
    switch (action.type) {
        case REGION_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case GET_REGION:
            return {
                ...state,
                regions: action.payload,
                isLoading: false
            }
        case ADD_TO_TEMP_FLEMISH:
            return {
                ...state,
                flemish: true,
                isLoading: false
            }
        case REMOVE_TEMP_FLEMISH:
            return {
                ...state,
                flemish: false,
                isLoading: false
            }
        case ADD_TO_TEMP_WALLOON:
            return {
                ...state,
                walloon: true,
                isLoading: false
            }
        case REMOVE_TEMP_WALLOON:
            return {
                ...state,
                walloon: false,
                isLoading: false
            }
        case CLEAR_REGION:
            return {
                ...state,
                regions: [],
                isLoading: true,
                flemish: false,
            }

        default:
            return state
    }
}