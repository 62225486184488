import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal, notification, Spin } from 'antd'
import { setResult } from '../../redux/result/resultActions'
import { NavLink } from 'react-router-dom'
import { addCartCost, addCartUser } from '../../redux/cart/actions'
import { PayPalButton } from 'react-paypal-button-v2'
import Loading from '../../components/auth/Loading'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'

const Result = React.memo(() => {
  const dispatch = useDispatch()
  const categories = useSelector((state) => state.categories)
  // console.log('result')
  const companyOutServer = useSelector((state) => state.result)
  console.log(companyOutServer.result)

  let allUnit = 0
  allUnit =
    companyOutServer.addressResult +
    companyOutServer.phoneResult +
    companyOutServer.emailResult +
    companyOutServer.websiteResult
  console.log('allUnit', allUnit)
  let costUnit = 0.02
  let result = allUnit * costUnit
  // result =
  //     companyOutServer.addressResult * costUnit +
  //     companyOutServer.phoneResult * costUnit +
  //     companyOutServer.emailResult * costUnit +
  //     companyOutServer.websiteResult * costUnit
  result = Math.round(result * 100) / 100
  // console.log(result)

  const refreshPage = () => {
    window.location.reload()
  }

  const addToCart = () => {
    console.log(result)
    dispatch(addCartCost(result))
  }
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    if (result <= 0) {
      console.log(categories)
      notification.open({
        message: 'Attention!',
        description: 'Enter Region and Category and select phone or mail!',
      })
    } else {
      setIsModalVisible(true)
      dispatch(addCartCost(result))
    }
  }

  // fake success button
  const region = useSelector((state) => state.region.regions)
  const category = useSelector((state) => state.category.categories)
  const address = useSelector((state) => state.address.addressActive)
  const phone = useSelector((state) => state.phone.phoneActive)
  const email = useSelector((state) => state.email.emailActive)
  const website = useSelector((state) => state.website.websiteActive)
  const cart = useSelector((state) => state.cart.cost)
  console.log('cart', cart)
  // useEffect(() =>{
  //
  // },[cart])

  let { user } = useAuth0()

  console.log(typeof region)
  console.log(user.email)
  user = user.email

  dispatch(addCartCost(result))
  const showModalFake = () => {
    console.log('fake log')

    // const data = { allUnit, region, user, category, address, phone, email, website }
    // axios.post('http://localhost:7000/api/success-payment/', data)
  }
// atriash1_rolan
// rjUHffff74gfujg#
  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const closePayModal = () => {
    setIsModalVisible(false)
  }
  // notification
  const openNotification = (message) => {
    notification.open({
      message: 'Success',
      description: message,
      onClick: () => {
        console.log('Notification Clicked!')
      },
    })
  }
  if (result !== 0 && typeof result !== 'number') {
    return <Loading />
  }
  return (
    <>
      <div className='result-top'>
        <div>
          <span>address: {companyOutServer.addressResult.toLocaleString()}</span>
          <br />
          <span>phone: {companyOutServer.phoneResult.toLocaleString()}</span>
          <br />
          <span>email: {companyOutServer.emailResult.toLocaleString()}</span>
          <br />
          <span>website: {companyOutServer.websiteResult.toLocaleString()}</span>
          <br />
          <strong>{costUnit} cents per unit</strong>
        </div>
        {/*<div>*/}
        {/*    <img style={{maxWidth: "70px"}}*/}
        {/*         src="/assets/rapiddb_2.png"*/}
        {/*         alt="logo mobile light"*/}
        {/*         className="header_logo_light"*/}
        {/*    />*/}
        {/*    <strong>result:</strong> {result.toLocaleString()} €*/}
        {/*</div>*/}
      </div>
      <div className='result-medium'>
        <strong>result:</strong> {result.toLocaleString()} €{/*<strong>categories:</strong><br/>*/}
      </div>

      {/*{categories.map(i => (*/}
      {/*    <>*/}
      {/*        <span key={i}>{i}</span><br/>*/}
      {/*        </>*/}
      {/*    ))}*/}

      <div className='result-bottom'>
        <Button onClick={refreshPage}>refresh</Button>
        <Button
          onClick={showModalFake}
          // type='primary'
          style={{ background: '#660a91' }}>
          {/*<NavLink to='/payment'>*/}
            <span style={{ color: '#fff' }}>add to card</span>
          {/*</NavLink>*/}
        </Button>

        {/*<Modal title='PayPal' visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>*/}
        {/*    <p>Category: {categories}</p>*/}
        {/*    <p>cost: € {result}</p>*/}
        {/*    <PayPalButton*/}
        {/*        // onButtonReady={() => {*/}
        {/*        //     console.log('ready')*/}
        {/*        //     return (*/}
        {/*        //         <>*/}
        {/*        //             <Spin/>*/}
        {/*        //         </>*/}
        {/*        //     );*/}
        {/*        // }}*/}
        {/*        amount={result}*/}
        {/*        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"*/}
        {/*        onSuccess={(details, data) => {*/}
        {/*            // alert("Transaction completed by " + details.payer.name.given_name);*/}
        {/*            openNotification('Transaction completed by ' + details.payer.name.given_name)*/}
        {/*            closePayModal()*/}
        {/*            // OPTIONAL: Call your server to save the transaction*/}
        {/*            return fetch('http://localhost:7000/api/paypal-transaction-complete', {*/}
        {/*                method: 'post',*/}
        {/*                body: JSON.stringify({*/}
        {/*                    orderId: data.orderID,*/}
        {/*                }),*/}
        {/*            })*/}
        {/*        }}*/}
        {/*        options={{*/}
        {/*            clientId: 'AezY-mAhFH28CkgQMmLo22Nfcj_4jQ1KjFatabRAYY_DTt231O1aSO1bt-Dd4WM1uOLzMtO0o4Jul4ZI', //info@atriashop.biz*/}
        {/*            // clientId: "AVpZYeSc09m4_wLyFvSzHuKbSi6tNELEG5XovAoOAy5ShQaGI-OeQELht_vLOPDwGXex1mXamYsamlmp",*/}
        {/*            currency: 'EUR',*/}
        {/*        }}*/}
        {/*    />*/}
        {/*</Modal>*/}
      </div>
    </>
  )
})

export default Result
