import {
    PRODUCTS_LOADING,
    GET_ALL_PRODUCTS,
    GET_ONE_REGION_PRODUCTS,
    GET_ONE_PRODUCT,
    CLEAR_PRODUCTS
} from './types'

const initialState = {
    products: [],
    isLoading: false,
}

export default function productReducer(state = initialState, action) {
    switch (action.type) {
        case PRODUCTS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case GET_ALL_PRODUCTS:
            console.log(action.payload)
            return {
                ...state,
                products: action.payload,
                isLoading: false
            }
        case GET_ONE_REGION_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                isLoading: false
            }
        case GET_ONE_PRODUCT:
            return {
                ...state,
                products: [...state.products, action.payload],
                isLoading: false
            }
        case CLEAR_PRODUCTS:
            return {
                ...state,
                products: [],
                isLoading: true
            }
        // case ADD_PRODUCT:
        //     return {
        //         ...state,
        //         isLoading: false,
        //         products: [...state.products, action.payload]
        //     }
        // case EDIT_PRODUCT:
        //     return {
        //         ...state,
        //         isEditing: true,
        //         isLoading: false,
        //         productBeingEdited: action.payload
        //     }
        // case CANCEL_EDIT:
        //     return {
        //         ...state,
        //         isEditing: false,
        //         isLoading: false,
        //         productBeingEdited: null
        //     }
        // case UPDATED_PRODUCT:
        //     return {
        //         ...state,
        //         products: state.products.map(product => (product._id === action.payload.id) ? action.payload : product),
        //         isEditing: false,
        //         isLoading: false,
        //         productBeingEdited: null
        //     }
        // case DELETE_PRODUCTS:
        //     return {
        //         ...state,
        //         products: state.products.filter(product => product._id !== action.payload),
        //         isEditing: false,
        //         isLoading: false
        //     }
        default:
            return state
    }
}