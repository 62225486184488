import React from "react";
import { Sidebar } from "./sidebar";
import HeaderBottom from "./header-bottom";
import Logic from "./header-bottom/Logic";
import SidebarInner from "./sidebar-inner";
import {HeroBg, VideoBg} from "./elements/HomeElements";
import {Wave2} from "./elements/TextAnimated";
import Video from "../../src/videos/world.mp4";
import { Typewriter } from "react-simple-typewriter";

export const LayoutNotAuth = ({ children, border }) => {
	const { toggleSidebarActive } = Logic();
	return (
		<div className="page" style={{background:'black',height:'100vh'}}>

			<HeroBg>
				<VideoBg autoPlay loop muted type="video/mp4" src={Video} />
			</HeroBg>

			<div className="container">

				<div className="start_page">

					<div className="start_head">
						{/*<span></span>*/}
						<div className="start_logo">
							{/*<img style={{width: 200, marginLeft:100}}*/}
							<img style={{width: 200}}
								 src="/assets/logo/2.png"
								 alt="logo"
								 className="sidebar_logo_light"
							/>
						</div>
						<span className="login" style={{color:'white'}}>
							<Wave2/>
						</span>
					</div>
					{/*style={{ fontSize: 90, paddingTop: '25rem', margin: 'auto 0', fontWeight: 'normal' }}*/}

					<h1 className="start_title" >
						Life is simple{' '}
						<span style={{ color: '#cd1447', fontWeight: 'bold' }}>
          {/* Style will be inherited from the parent element */}
							<Typewriter
								words={['Eat', 'Sleep', 'Code', 'Repeat!']}
								loop={false}
								cursor
								cursorStyle='_'
								typeSpeed={200}
								deleteSpeed={50}
								delaySpeed={1000}
								// onLoopDone={handleDone}
								// onType={handleType}
							/>
        </span>
					</h1>
				</div>

			</div>

		</div>
	);
};
