import React from "react";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import Logic from "./Logic";
import {AiOutlineHome, AiOutlineLogin} from "react-icons/ai/index";
import {BiBell} from "react-icons/bi";
import {useAuth0} from "@auth0/auth0-react";

export const Navbar = () => {
    const {logout, loginWithRedirect, user, isAuthenticated, getAccessTokenSilently} = useAuth0();
    const location = useLocation()

    const {toggleSidebarActive} = Logic();
    const Navigations = [
        {
            id: 0,
            Icon: AiOutlineHome,
            label: "Home",
            path: "/",
        },
        {
            id: 1,
            Icon: BiBell,
            label: "Custom scraper",
            path: "/custom-scraper",
        },
    ];
    const NavigationsAuth = [
        {
            id: 3,
            Icon: AiOutlineLogin,
            label: "Log In",
            path: "/login",
        },
    ];

    return (
        <nav className="header-nav">
            {isAuthenticated ?
                <>
                    {Navigations.map((item) => {
                        const {id, Icon, label, path} = item;
                        return (
                            <NavLink to={path}
                                     className="header_nav_item"
                                     key={id}>
                                {/*<div className="header_nav_icon">*/}
                                <div className={location.pathname === path ? "header_nav_icon active_link_bottom" : "header_nav_icon"}>
                                    <Icon className="icon"/>
                                </div>
                            </NavLink>
                        );
                    })}
                    <button
                        className="header_nav_item"
                        onClick={toggleSidebarActive}
                        aria-label="menu">
                        <span className="header_nav_icon">
                            <img src={user.picture} alt=""/>
                        </span>
                    </button>
                </>
                :
                <>
                    {NavigationsAuth.map((item) => {
                        const {id, Icon, label, path} = item;
                        return (
                            <NavLink onClick={loginWithRedirect} to={path} className="header_nav_item" key={id}>
                                <div className="header_nav_icon">
                                    <Icon className="icon"/>
                                </div>
                            </NavLink>
                        );
                    })}
                </>
            }
        </nav>
    );
};
// AiOutlineLogout