import {
	SET_RESULT_WEBSITE,
	SET_RESULT_EMAIL,
	SET_RESULT_PHONE,
	SET_RESULT_ADDRESS,
	SET_RESULT
} from "./type";


const initialState = {
	addressResult: 0,
	phoneResult: 0,
	emailResult: 0,
	websiteResult: 0,
	result: 0
}
// const initialState = []
const reducerResult = (state = initialState, action) => {

	switch (action.type) {
		case SET_RESULT_ADDRESS:
			let {addressResult} = action.addressResult
			return {
				...state,
				addressResult: addressResult
			}
		case SET_RESULT_PHONE:
			const {phoneResult} = action.phoneResult
			return {
				...state,
				phoneResult
			}
		case SET_RESULT_EMAIL:
			const {emailResult} = action.emailResult
			return {
				...state,
				emailResult
			}
		case SET_RESULT_WEBSITE:
			const {websiteResult} = action.websiteResult
			return {
				...state,
				websiteResult
			}
		case SET_RESULT:
			const {setResult} = action.setResult
			return {
				...state,
				result: setResult
			}
		default:
			return state;
	}
};

export default reducerResult;
