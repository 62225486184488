import {SET_EMAIL, FALSE_EMAIL} from "./type";

const initialState = {
	emailActive: false
}
const emailReducer = (state = initialState, action) => {

	switch (action.type) {
		case SET_EMAIL:
			return {
				...state,
				emailActive: action.payload
			}
		case FALSE_EMAIL:
			return {
				...state,
				emailActive: false
			}
		default:
			return state;
	}
};

export default emailReducer;
