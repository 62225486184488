import React from 'react';
import {Navbar} from "./Navbar";

const HeaderBottom = () => {
    return (
        <div className="header-bottom">
            {/*<nav className="header-bottom_menu">*/}
                <Navbar/>
            {/*</nav>*/}
        </div>
    );
};

export default HeaderBottom;