import {GET_NEW_ARR_PRODUCT, CLEAR_NEW_ARR_PRODUCT} from "./type";

export const getNewArrProduct = (payload) => {
    return {
        type: GET_NEW_ARR_PRODUCT,
        payload: payload
    };
};
export const clearNewArrProduct = () => {
    return {
        type: CLEAR_NEW_ARR_PRODUCT,
    };
};

