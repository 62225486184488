import React from 'react';
import { Select } from 'antd';
import text from './data_categories'
import {useDispatch, useSelector} from "react-redux";
import {clearProducts} from "../../redux/product/productAction";
import {getCategory} from "../../redux/category/categoryAction";


const SelectCategories = () => {
    const { Option } = Select;
    const children = [];
    text.map((i) => {
        children.push(<Option key={i.title}>{i.title}</Option>);
    })

    const dispatch = useDispatch()
    // const {regions} = useSelector((state => state.region))
    //
    // // --------- clear products ---------
    // if (regions.length === 0){
    //     dispatch(clearProducts())
    // }

    // --------- get category ---------
    function handleChange(value) {
        dispatch(getCategory(value))
    }

    return (
        <>
            <Select
                className="select_categories_custom"
                mode="tags"
                style={{ width: '100%' }}
                placeholder="select categories"
                onChange={handleChange}
                // loading
            >
                {children}
            </Select>
        </>
    );
};

export default SelectCategories;