import {SET_PHONE, FALSE_PHONE} from "./type";

export const setPhone = (payload) => {
    return {
        type: SET_PHONE,
        payload: payload
    };
};
export const falsePhone = () => {
    return {
        type: FALSE_PHONE,
        payload: false
    };
};
