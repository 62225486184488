import React, {useEffect, useState} from "react";
import {Layout} from "../../components/Layout";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
// import {nullRegion} from "../../redux/region/actions";
// import {nullCategory} from "../../redux/categories/actions";
import products from './data.json'
import data from './data.png'

const CustomScraper = ({coins}) => {


    return (
        <Layout><h2 style={{textAlign: 'center'}}>Custom scraper</h2>
            <div className="page_container">
                <p>On this page you can order a parser for your needs..</p>
                <p>What is a parser for?...</p>
                <a href="tel:+32 4444444"><span>+32 457 44 56</span></a><br/>
                <a href="mailto:info@rapiddb.biz">info@rapiddb.biz</a>
            </div>
            <div className="fid-back" style={{marginTop:100}}>
                <p>Напишите нам чтобы вы хотели получить от нашего сервиса.</p>
                <p>Это позволит нам нам быть лучше.</p>
                <textarea name="fid-back" id="" cols="30" rows="10"></textarea><br/>
                <button>send</button>
            </div>
            {/*<div className="page_container">*/}
            {/*    {products.map(product => {*/}
            {/*        return (*/}
            {/*            <div key={product.id}>*/}
            {/*                <div>*/}

            {/*                        <img src={data} alt={`Preview of ${product.title}`}/>*/}
            {/*                        <h3>{product.title}</h3>*/}
            {/*                        <p>{product.description}</p>*/}
            {/*                        <p>€{product.price}</p>*/}

            {/*                </div>*/}
            {/*                <p>*/}
            {/*                    <button className="snipcart-add-item"*/}
            {/*                            data-item-id={product.id}*/}
            {/*                            data-item-image={product.image}*/}
            {/*                            data-item-name={product.title}*/}
            {/*                            data-item-url={`/products/${product.id}`}*/}
            {/*                            data-item-price={Number.parseInt(product.price)}*/}
            {/*                    >*/}
            {/*                        Add to Cart*/}
            {/*                    </button>*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*        );*/}
            {/*    })}*/}
            {/*</div>*/}
        </Layout>
    );
};

export default CustomScraper;
