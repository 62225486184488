import {
    CATEGORY_LOADING,
    GET_CATEGORY,
    CLEAR_CATEGORY
} from './types'

const initialState = {
    categories: [],
    isLoading: false,
}

export default function categoryReducer(state = initialState, action) {
    switch (action.type) {
        case CATEGORY_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case GET_CATEGORY:
            return {
                ...state,
                categories: action.payload,
                isLoading: false
            }
        case CLEAR_CATEGORY:
            return {
                ...state,
                categories: [],
                isLoading: true
            }

        default:
            return state
    }
}