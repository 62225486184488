import React, {useEffect, useState} from "react";
import {Layout} from "../../components/Layout";
import {LayoutNotAuth} from "../../components/LayoutNotAuth";
import {useAuth0} from "@auth0/auth0-react";
import Loading from "../../components/auth/Loading";
import {Popconfirm, Button, Modal, message} from "antd";
import CookieConsent from "react-cookie-consent";
import SwitchAddress from "../../components/switch/SwitchAddress";
import SwitchPhone from "../../components/switch/SwitchPhone";
import SwitchEmail from "../../components/switch/SwitchEmail";
import SwitchWebsite from "../../components/switch/SwitchWebsite";
import RegionTreeSelectFunction from "./RegionTreeSelectFunction";
import SelectCategories from "./SelectCategories";
import QueryData from "./queryData";
import {clearRegion} from "../../redux/region/regionAction";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {clearProducts} from "../../redux/product/productAction";
import CardCalculator from "./CardCalculator";
import Result from "./Result";
import {loadStripe} from "@stripe/stripe-js";


const Home = () => {
    let brussels = [
        {"name": "Brussels", "key": "0-0-0-0", "company": 1, "phone": 0, "mail": 1, "web": 1}
    ]
    let flanders = [
        {
            "name": "Antwerp Province",
            "key": "0-0-1-0",
            "company": 7,
            "phone": 2,
            "mail": 3,
            "web": 3
        }, {
            "name": "Limburg Province",
            "key": "-2",
            "company": 0,
            "phone": 0,
            "mail": 0,
            "web": 0
        }, {
            "name": "East Flanders Province",
            "key": "0-0-1-2",
            "company": 3,
            "phone": 2,
            "mail": 1,
            "web": 1
        }, {
            "name": "Flemish Brabant Province",
            "key": "0-0-1-3",
            "company": 1,
            "phone": 0,
            "mail": 0,
            "web": 0
        }, {"name": "West Flanders Province", "key": "0-0-1-4", "company": 6, "phone": 4, "mail": 4, "web": 3}]
    let wallonia = [
        {
            "name": "Hainaut Province",
            "key": "0-0-2-0",
            "company": 1,
            "phone": 1,
            "mail": 1,
            "web": 0
        }, {
            "name": "Liège Province",
            "key": "0-0-2-1",
            "company": 2,
            "phone": 0,
            "mail": 1,
            "web": 1
        }, {
            "name": "Luxembourg Province",
            "key": "0-0-2-2",
            "company": 1,
            "phone": 1,
            "mail": 1,
            "web": 1
        }, {
            "name": "Namur Province",
            "key": "0-0-2-3",
            "company": 1,
            "phone": 1,
            "mail": 0,
            "web": 0
        }, {"name": "Walloon Brabant Province", "key": "0-0-2-4", "company": 0, "phone": 0, "mail": 0, "web": 0}]
    const dispatch = useDispatch()
    const location = useLocation()
    useEffect(() => {
        if (location.path !== '/') {
            dispatch(clearProducts())
            dispatch(clearRegion())
        }
    }, [location.path])
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const {isLoading, isAuthenticated, user} = useAuth0();
    // console.log(user.email_verified)
    if (isLoading) {
        return <Loading/>
    }

    function confirmJson() {
        message.info('Selected json.');
    }

    function confirmText() {
        message.info('Selected text.');
    }


    if (!isAuthenticated) {
        return (
            <LayoutNotAuth border>
                <div className="page_row">
                    <div className="page_col">
                        <div className="error_auth text-center" style={{marginBottom: 20}}>
                            <p>Register and get access to all functionality.</p>

                            <img src="/assets/animation.gif" alt=""/>
                        </div>
                    </div>
                </div>
            </LayoutNotAuth>
        )
    }
    if (!user.email_verified) {
        return (
            <Layout border>
                <div className="page_row">
                    <div className="page_col">
                        <div className="error_auth text-center" style={{marginBottom: 20}}>
                            <p>Please confirm your email before logging in.</p>

                            <img src="/assets/animation.gif" alt=""/>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
    if (user.email_verified) {
        return (
            <>

                <Layout border>
                    <div className="page_row">
                        <div className="page_col">

                            <div className="home_top text-center">
                                <h1 style={{margin: 0}}>Collect your database of your leads.</h1>
                                <p style={{margin: 0}}>Pay only for the data that interests you.</p>
                                {/*<Tooltip placement="top" title="Инструкция создания списка email рассылки">*/}
                                <Button onClick={showModal}>example</Button>
                                {/*</Tooltip>*/}
                                <Modal title="Instruction" visible={isModalVisible} onOk={handleOk}
                                       onCancel={handleCancel}>
                                    <img src="/assets/animation.gif" alt=""/>

                                </Modal>
                            </div>

                            {/*block result*/}
                            {/*margin bottom and border radius (card card_widget) */}
                            <div className="select-categories card_widget">
                                <Result/>
                                <CardCalculator/>
                            </div>

                            <div className="select-categories card_button">
                                <h3>Select the region, category and options you are interested in.</h3>
                                <div className="select-categories-top">

                                    <div className="switch_container">
                                        {/*<CheckbocTest/>*/}
                                        <SwitchAddress/>
                                        <SwitchPhone/>
                                        <SwitchEmail/>
                                        <SwitchWebsite/>
                                    </div>

                                </div>


                                <div className="select-categories-middle">
                                    <div className="tree_region">
                                        <div className="tree_region_title">
                                            <span>Regions Belgium</span>
                                        </div>
                                        <RegionTreeSelectFunction/>
                                        <QueryData/>

                                    </div>

                                    <div className="tree_categories">
                                        <div className="tree_region_title">
                                        <span>Category <span
                                            style={{fontSize: 12, color: '#b9b9b9'}}>(1615 categories)</span></span>
                                        </div>
                                        <SelectCategories/>
                                    </div>
                                </div>
                                <div className="select_example-file">
                                    <h3>Choose a file format:</h3>
                                    <Popconfirm
                                        placement="topLeft"
                                        title={() => {
                                            return (
                                                <div>
                                                        <pre className="gatsby-code-text">
                                                            <code
                                                                className="gatsby-code-text">
                                                            {/*[*/}

                                                                <span>example json file</span><br/>
                                                                {`{`}<br/>
                                                                {`"title": "Lunch Garden",`}<br/>
                                                                {`"category": "Restaurants",`}<br/>
                                                                {`"region": "Hainaut Province",`}<br/>
                                                                {`"city": "7800 Aat",`}<br/>
                                                                {`"address": "Louizalaan 164",`}<br/>
                                                                {`"web": "http://www.ath.be",`}<br/>
                                                                {`"mail": "fjonckers@ath.be",`}<br/>
                                                                {`"phone": "0496 40 63 40"`}<br/>
                                                                {`},`}
                                                                <br/>
                                                        </code>
                                                        </pre>
                                                    <span style={{color: 'red'}}>Confirm your choice.</span>
                                                </div>
                                            )
                                        }}
                                        onConfirm={confirmJson}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button>json file</Button>
                                    </Popconfirm>

                                    <Popconfirm
                                        placement="top"
                                        title={() => {
                                            return (
                                                <div>
                                                        <pre className="gatsby-code-text">
                                                            <code
                                                                className="gatsby-code-text">
                                                            {/*[*/}

                                                                <span>example text file</span><br/>
                                                                <span>mail list</span><br/>
                                                                <br/>
                                                                info@t-z.be<br/>
                                                                gino.deroover@skynet.be<br/>
                                                                skyhunter.info@gmail.com<br/>
                                                                lasouricelle@gmail.com<br/>
                                                                lasouricelle@gmail.com<br/>
                                                                lasouricelle@gmail.com<br/>
                                                                lasouricelle@gmail.com<br/>
                                                                info@msvt.be<br/>
                                                                ...

                                                                <br/>
                                                        </code>
                                                        </pre>
                                                    <span style={{color: 'red'}}>Confirm your choice.</span>
                                                </div>
                                            )
                                        }}
                                        onConfirm={confirmText}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button style={{marginLeft: 10}}>text file</Button>
                                    </Popconfirm>

                                </div>
                            </div>


                        </div>
                    </div>

                    <CookieConsent
                        location="bottom"
                        buttonText="Sure man!!"
                        cookieName="myAwesomeCookieName2"
                        style={{background: "#2B373B"}}
                        buttonStyle={{color: "#4e503b", fontSize: "13px"}}
                        expires={150}
                    >
                        This website uses cookies to enhance the user experience.{" "}
                        {/*<span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span>*/}
                    </CookieConsent>

                </Layout>

            </>
        );
    }

};

export default Home;
