import {
    CATEGORY_LOADING,
    GET_CATEGORY,
    CLEAR_CATEGORY
} from './types'

export const getCategory = (payload) => async dispatch => {
    dispatch({
        type: GET_CATEGORY,
        payload: payload
    })
}

export const clearCategory = () => dispatch => {
    dispatch({
        type: CLEAR_CATEGORY
    })
}

