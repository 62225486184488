import {
	GET_PROFILE_EMAIL,
	GET_PROFILE,
	SET_PROFILE
} from "./type";

const initialState = {
	email: '',
	item_buy: [],
}
const profileReducer = (state = initialState, action) => {

	switch (action.type) {
		case GET_PROFILE_EMAIL:
			return {
				...state,
				email: action.payload
			}
		case GET_PROFILE:
			return {
				...state,
				item_buy: action.payload
			}
		default:
			return state;
	}
};

export default profileReducer;
