import {GET_NEW_ARR_PRODUCT, CLEAR_NEW_ARR_PRODUCT} from "./type";

const initialState = {
	newArrProducts: []
}
const getNewArrProduct = (state = initialState, action) => {

	switch (action.type) {
		case GET_NEW_ARR_PRODUCT:
			return {
				...state,
				newArrProducts: action.payload
			}
		case CLEAR_NEW_ARR_PRODUCT:
			return {
				...state,
				newArrProducts: []
			}
		default:
			return state
	}
};

export default getNewArrProduct;
