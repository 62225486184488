import React, {useEffect} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {
    AiOutlineBarChart,
    AiOutlineHome,
    AiOutlineLogin,
    AiOutlineLogout,
    AiOutlineWallet,
    AiOutlineProfile
} from "react-icons/ai/index";
import {useDispatch, useSelector} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";
import {addCartUser} from "../../redux/cart/actions";
import useWindowDimensions from "../../components/getWindowDimensions"
import {SwitchTheme} from "./SwitchTheme";
import AddUser from "./addUser"


export const Navbar = () => {
    const {width} = useWindowDimensions();
    const location = useLocation()
    // console.log(location.pathname)

    const {logout, loginWithRedirect, user, isAuthenticated, getAccessTokenSilently} = useAuth0();

    const dispatch = useDispatch()

    const Navigations = [
        {
            id: 0,
            Icon: AiOutlineHome,
            label: "Home",
            path: "/",
        },
        {
            id: 1,
            Icon: AiOutlineBarChart,
            label: "Custom scraper",
            path: "/custom-scraper",
        },
        {
            id: 2,
            Icon: AiOutlineWallet,
            label: "Test",
            path: "/test",
        },
    ];
    const NavigationsAuth = [
        // AiOutlineProfile
        {
            id: 3,
            Icon: AiOutlineLogin,
            label: "Log In",
            path: "/login",
        },
    ];

    // useEffect(() => {
    //     dispatch(addCartUser(user.email))
    // }, [isAuthenticated])

    return (
        <nav className="sidebar_nav">
            {isAuthenticated ?
                <>
                    <div className="sidebar_nav-links">
                        {/*<NavLink to='profile' className={location.pathname === "/profile" ? "sidebar_item active_link profile" : "sidebar_item profile"} key="profile">*/}
                        <NavLink to='profile'
                                 className={location.pathname === "/profile" ? "sidebar_item active_link profile" : "sidebar_item profile"}
                                 key="profile">
                            {/*<div className="sidebar_icon">*/}
                            {/*    <AiOutlineProfile className="icon"/>*/}
                            {/*</div>*/}
                            <div className="sidebar_icon">
                                <img src={user.picture} alt=""/>
                            </div>
                            {width >= 900 ?
                                <span className="sidebar_text">{user.name}</span>
                                :
                                <></>
                            }
                        </NavLink>
                        <div className="profile-border"></div>
                        {Navigations.map((item) => {
                            const {id, Icon, label, path} = item;
                            return (
                                <NavLink to={path}
                                         className={location.pathname === path ? "sidebar_item active_link" : "sidebar_item"}
                                         key={id}>
                                    <div className="sidebar_icon">
                                        <Icon className="icon"/>
                                    </div>
                                    {width >= 900 ?
                                        <span className="sidebar_text">{label}</span>
                                        :
                                        <></>
                                    }

                                </NavLink>
                            );
                        })}
                    </div>

                    <SwitchTheme/>

                    <div className="border"></div>

                    <div className="sidebar_nav-logout">
                        <NavLink onClick={() =>
                            logout({
                                returnTo: window.location.origin,
                            })}
                                 to='login'
                                 className={location.pathname === "/login" ? "sidebar_item active_link" : "sidebar_item"}
                                 key="login">
                            <div className="sidebar_icon">
                                <AiOutlineLogout className="icon"/>
                            </div>
                            {width >= 900 ?
                                <span className="sidebar_text">Log Out</span>
                                :
                                <></>
                            }
                        </NavLink>
                    </div>

                </>
                :
                <>
                    {NavigationsAuth.map((item) => {
                        const {id, Icon, label, path} = item;
                        return (
                            // <div  onClick={() => dispatch(logout()) } className="sidebar_item" key={id}>
                            <NavLink onClick={loginWithRedirect} to={path} className="sidebar_item login" key={id}>
                                <div className="sidebar_icon">
                                    <Icon className="icon"/>
                                </div>
                                {width >= 900 ?
                                    <div className="sidebar_text">{label}</div>
                                    :
                                    <></>
                                }

                            </NavLink>
                        );
                    })}
                    <div className="border"></div>
                    <SwitchTheme/>

                </>
            }
        </nav>
    );
};
// AiOutlineLogout