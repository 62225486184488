import {createStore, combineReducers, applyMiddleware} from "redux";
import thunk from "redux-thunk";

import {composeWithDevTools} from 'redux-devtools-extension';
// import userReducer from "./auth/reducer";
// import reducerCart from "./cart/reducer";
import themeReducer from "./theme/themeReducer";
import productReducer from "./product/productReducer";
import regionReducer from "./region/regionReducer";
import categoryReducer from "./category/categoryReducer";
import phoneReducer from "./phone/phoneReducer";
import addressReducer from "./address/addressReducer";
import emailReducer from "./email/emailReducer";
import websiteReducer from "./website/websiteReducer";
import resultReducer from "./result/resultReducer";
import reducerCart from "./cart/reducerCart";
import profileReducer from "./profile/profileReducer";
import getNewArrProduct from "./notDubleProduct/getNewArrProductReducer";

const store = createStore(
    combineReducers({
        theme: themeReducer,
        product: productReducer,
        region: regionReducer,
        category: categoryReducer,
        phone: phoneReducer,
        address: addressReducer,
        email: emailReducer,
        website: websiteReducer,
        result: resultReducer,
        cart: reducerCart,
        profile: profileReducer,
        newArrProduct: getNewArrProduct,
    }),
    composeWithDevTools(applyMiddleware(thunk))
);

export default store;