import { THEME_DARK, THEME_LIGHT } from "./type";

const initialState = {
	isDark: true,
};

const themeReducer = (state = initialState, action) => {
	switch (action.type) {
		case THEME_DARK:
			return { isDark: true };
		case THEME_LIGHT:
			return { isDark: false };
		default:
			return state;
	}
};

export default themeReducer;
