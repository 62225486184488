import {ADD_CART_COST, ADD_CART_USER} from "./type";

export const addCartUser = (user) => {
    return {
        type: ADD_CART_USER,
        payload: user
    };
};

export const addCartCost = (cost) => {
    return {
        type: ADD_CART_COST,
        payload: cost
    };
};
