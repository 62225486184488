import React, {useEffect, useState} from 'react';
import {Switch} from 'antd';
import {useDispatch} from "react-redux";
import {falseAddress, setAddress,} from "../../redux/address/addressActions";
import {useLocation} from "react-router-dom";

const SwitchAddress = () => {

    const dispatch = useDispatch()

    function onChange(checked) {
        dispatch(setAddress(checked))
    }
    const location = useLocation()
    useEffect(() => {
        if (location.path !== '/'){
            dispatch(falseAddress())
        }
    }, [location.path])

    return (
        <div className="switch_box">
            <label>
                <Switch
                    defaultChecked={false}
                    onChange={onChange}
                />
                Address and postal code
            </label>
        </div>
    );
};

export default SwitchAddress;