import React from 'react'
import { Navbar } from './Navbar'
import useWindowDimensions from '../../components/getWindowDimensions'

export const Sidebar = () => {
  const { width } = useWindowDimensions()
  // console.log('width', width)

  return (
    <aside className='sidebar'>
      <div className='sidebar_head'>
        <div className='sidebar_logo'>
          {width > 898 ? (
            <img src='/assets/logo/logo-rapiddb.png' alt='logo' className='sidebar_logo_light' />
          ) : (
            <img
              src='/assets/logo/logo-rapiddb-small.png'
              alt='logo'
              className='sidebar_logo_light'
            />
          )}
        </div>
      </div>

      <div className='sidebar_body'>
        <Navbar />
      </div>

      {/*<div className="sidebar_bottom">*/}
      {/*	/!*<SwitchTheme />*!/*/}
      {/*</div>*/}
    </aside>
  )
}
