import {
    GET_PROFILE_EMAIL,
    GET_PROFILE,
    SET_PROFILE
} from "./type";

export const getProfileEmail = (payload) => {
    return {
        type: GET_PROFILE_EMAIL,
        payload: payload
    };
};
export const getProfile = (payload) => {
    return {
        type: GET_PROFILE,
        payload: payload
    };
};
