import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setResultAddress,
  setResultEmail,
  setResultPhone,
  setResultWebsite,
} from '../../redux/result/resultActions'
import {
  clearNewArrProduct,
  getNewArrProduct,
} from '../../redux/notDubleProduct/getNewArrProductActions'

const CardCalculator = () => {
  const dispatch = useDispatch()
  const { products } = useSelector((state) => state.product)

  useEffect(() => {
    try {
      // remove duble products
      if (products.length !== 0) {
        let uniq = {}
        // console.log('uniq.length', uniq.length)
        // console.log('products.length', products.length)
        // console.log('products', products)
        // let arrProvince = products.filter(obj => !uniq[obj.id] && (uniq[obj.id] = true));
        let arrProvince = products.filter(
          (obj) => obj.id !== null && !uniq[obj.id] && (uniq[obj.id] = true),
        )

        // console.log('arrProvince', arrProvince)
        dispatch(getNewArrProduct(arrProvince))
      }
      if (products.length === 0) {
        dispatch(clearNewArrProduct())
      }
    } catch (e) {
      console.log(e)
    }
  }, [products])

  const { newArrProducts } = useSelector((state) => state.newArrProduct)
  // console.log('product.length', newArrProducts.length)

  const { regions } = useSelector((state) => state.region)

  const { categories } = useSelector((state) => state.category)

  const { addressActive } = useSelector((state) => state.address)
  const { phoneActive } = useSelector((state) => state.phone)
  const { emailActive } = useSelector((state) => state.email)
  const { websiteActive } = useSelector((state) => state.website)
  // console.log(addressActive,phoneActive,emailActive,websiteActive)

  const region = 'Brussels Capital Region'
  // useEffect(() =>  {
  //     function filterProduct(category, newArrProducts) {
  //         try {
  //
  //             let a = 0
  //             let p = 0
  //             let m = 0
  //             let w = 0
  //             categories.map(category => { // Abrasives & polishing compounds
  //                 // console.log('product.length', newArrProducts.length)
  //                 newArrProducts.map(product => { //{id: 4, region: 'Flanders', key: '0-0-1', title: 'Abrasives & polishing compounds', city: Array(5),……}
  //
  //
  //                     regions.map(region => { // 0-0-1
  //                         // console.log(region)
  //                         // console.log(region)
  //                         if (region && region.length === 5) {
  //                             if (product.key === region) { // 0-0-1 , 0-0-2 or two items
  //                                 // console.log(product.key, region)
  //                                 product.city.map(c => {
  //
  //                                     if (addressActive === true) {
  //                                         a += c.company
  //                                         dispatch(setResultAddress(a))
  //                                     } else dispatch(setResultAddress(0))
  //
  //                                     if (phoneActive === true) {
  //                                         p += c.phone
  //                                         dispatch(setResultPhone(p))
  //                                     } else dispatch(setResultPhone(0))
  //
  //                                     if (emailActive === true) {
  //                                         m += c.mail
  //                                         dispatch(setResultEmail(m))
  //                                     } else dispatch(setResultEmail(0))
  //
  //                                     if (websiteActive === true) {
  //                                         w += c.web
  //                                         dispatch(setResultWebsite(w))
  //                                     } else dispatch(setResultWebsite(0))
  //                                 })
  //
  //                             }
  //                         }
  //                         // console.log('???',product.key, region)
  //                         // if (product.key[4] === region[4] && region.length === 7) {
  //                         //     console.log('!!!',product.key, region)
  //                         //     console.log('!!!',product)
  //                         //     if (product.title === category) { // проверка названия категории
  //                         //         console.log(product.title, category)
  //                         //         product.city.map(c => {
  //                         //             console.log(c)
  //                         //             // c.filter(c.key === region).map(f => {
  //                         //             //     console.log(f)
  //                         //                 if (addressActive === true) {
  //                         //                     a += c.company
  //                         //                     console.log(a, '|',c.company)
  //                         //                     dispatch(setResultAddress(a))
  //                         //                 } else dispatch(setResultAddress(0))
  //                         //             // })
  //                         //             // {
  //                         //             //     console.log(c.key,'|',region)
  //                         //             //     if (addressActive === true) {
  //                         //             //         a += c.company
  //                         //             //         console.log(a, '|',c.company)
  //                         //             //         dispatch(setResultAddress(a))
  //                         //             //     } else dispatch(setResultAddress(0))
  //                         //             //
  //                         //             //     if (phoneActive === true) {
  //                         //             //         p += c.phone
  //                         //             //         dispatch(setResultPhone(p))
  //                         //             //     } else dispatch(setResultPhone(0))
  //                         //             //
  //                         //             //     if (emailActive === true) {
  //                         //             //         m += c.mail
  //                         //             //         dispatch(setResultEmail(m))
  //                         //             //     } else dispatch(setResultEmail(0))
  //                         //             //
  //                         //             //     if (websiteActive === true) {
  //                         //             //         w += c.web
  //                         //             //         dispatch(setResultWebsite(w))
  //                         //             //     } else dispatch(setResultWebsite(0))
  //                         //             // }
  //                         //
  //                         //
  //                         //         })
  //                         //     }
  //                         // }
  //
  //                         if (region === '0-0') {
  //
  //                             if (product.title === category) { // проверка названия категории
  //                                 // console.log(product.title, category)
  //                                 product.city.map(c => {
  //
  //
  //                                     if (c.key) {
  //
  //                                         if (addressActive === true) {
  //                                             a += c.company
  //                                             dispatch(setResultAddress(a))
  //                                         } else dispatch(setResultAddress(0))
  //
  //                                         if (phoneActive === true) {
  //                                             p += c.phone
  //                                             dispatch(setResultPhone(p))
  //                                         } else dispatch(setResultPhone(0))
  //
  //                                         if (emailActive === true) {
  //                                             m += c.mail
  //                                             dispatch(setResultEmail(m))
  //                                         } else dispatch(setResultEmail(0))
  //
  //                                         if (websiteActive === true) {
  //                                             w += c.web
  //                                             dispatch(setResultWebsite(w))
  //                                         } else dispatch(setResultWebsite(0))
  //                                     }
  //
  //
  //                                 })
  //                             }
  //
  //                         }
  //                     })
  //
  //                 })
  //
  //             })
  //             console.log(a,p,m,w)
  //             if (regions.length === 0 || categories.length === 0) {
  //                 dispatch(setResultAddress(0))
  //                 dispatch(setResultPhone(0))
  //                 dispatch(setResultEmail(0))
  //                 dispatch(setResultWebsite(0))
  //                 // dispatch(setCheckChecked())
  //             }
  //         } catch (e) {
  //         }
  //     }
  //     filterProduct(categories, newArrProducts)
  // },[newArrProducts, regions, categories, phoneActive, addressActive, emailActive, websiteActive, setResultAddress, setResultPhone, setResultEmail, setResultWebsite])
  useEffect(() => {
    function filterProduct(category, products) {
      try {
        let a = 0
        let p = 0
        let m = 0
        let w = 0
        categories.map((category) => {
          // CD's & DVD's - Mfg. & Whol.

          products.map((product) => {
            //{id: 4, region: 'Flemish Region', key: '0-0-1', title: "CD's & DVD's - Retail", city: Array(2), …}
            // console.log('product', product)

            regions.map((region) => {
              // 0-0-0

              if (region) {
                if (product.title === category) {
                  // проверка названия категории

                  product.city.map((c) => {
                    if (c.key === region) {
                      if (addressActive === true) {
                        a += c.company
                        dispatch(setResultAddress(a))
                      } else dispatch(setResultAddress(0))

                      if (phoneActive === true) {
                        p += c.phone
                        dispatch(setResultPhone(p))
                      } else dispatch(setResultPhone(0))

                      if (emailActive === true) {
                        m += c.mail
                        dispatch(setResultEmail(m))
                      } else dispatch(setResultEmail(0))

                      if (websiteActive === true) {
                        w += c.web
                        dispatch(setResultWebsite(w))
                      } else dispatch(setResultWebsite(0))
                    }
                  })
                }
              }
              if (product.key === region) {
                console.log('region!!!!!!!!!!!!!!!!!???????????', region)
                if (product.title === category) {
                  // проверка названия категории

                  product.city.map((c) => {
                    if (c.key) {
                      if (addressActive === true) {
                        a += c.company
                        dispatch(setResultAddress(a))
                      } else dispatch(setResultAddress(0))

                      if (phoneActive === true) {
                        p += c.phone
                        dispatch(setResultPhone(p))
                      } else dispatch(setResultPhone(0))

                      if (emailActive === true) {
                        m += c.mail
                        dispatch(setResultEmail(m))
                      } else dispatch(setResultEmail(0))

                      if (websiteActive === true) {
                        w += c.web
                        dispatch(setResultWebsite(w))
                      } else dispatch(setResultWebsite(0))
                    }
                  })
                }
              }
              if (region === '0-0') {
                if (product.title === category) {
                  // проверка названия категории
                  //   console.log(product.title)
                  product.city.map((c) => {
                    if (c.key) {
                      if (addressActive === true) {
                        a += c.company
                        dispatch(setResultAddress(a))
                      } else dispatch(setResultAddress(0))

                      if (phoneActive === true) {
                        p += c.phone
                        dispatch(setResultPhone(p))
                      } else dispatch(setResultPhone(0))

                      if (emailActive === true) {
                        m += c.mail
                        dispatch(setResultEmail(m))
                      } else dispatch(setResultEmail(0))

                      if (websiteActive === true) {
                        w += c.web
                        dispatch(setResultWebsite(w))
                      } else dispatch(setResultWebsite(0))
                    }
                  })
                }
              }
            })
          })
        })
        // console.log(a, p, m, w)
        if (regions.length === 0 || categories.length === 0) {
          dispatch(setResultAddress(0))
          dispatch(setResultPhone(0))
          dispatch(setResultEmail(0))
          dispatch(setResultWebsite(0))
          // dispatch(setCheckChecked())
        }
      } catch (e) {}
    }
    filterProduct(categories, newArrProducts)
  }, [
    newArrProducts,
    regions,
    categories,
    addressActive,
    phoneActive,
    emailActive,
    websiteActive,
    setResultAddress,
    setResultPhone,
    setResultEmail,
    setResultWebsite,
  ])

  return <></>
}

export default CardCalculator
