const text =
    [
            {"title":"Abattoirs"},
            {"title":"Abrasives & polishing compounds"},
            {"title":"Access-control (Systems for)"},
            {"title":"Accountants"},
            {"title":"Accumulators"},
            {"title":"Acoustic consultants"},
            {"title":"Actuaries"},
            {"title":"Acupuncture"},
            {"title":"Addressing & mailing service"},
            {"title":"Adhesive materials"},
            {"title":"Adult education"},
            {"title":"Advertising - Aerial"},
            {"title":"Advertising - Bill posting"},
            {"title":"Advertising - Promotional items & business gifts"},
            {"title":"Advertising - Three-dimensional"},
            {"title":"Advertising agencies & consultants"},
            {"title":"Advertising clothes"},
            {"title":"Advertising films"},
            {"title":"Advertising painters"},
            {"title":"Advertising photography"},
            {"title":"Advertising studios"},
            {"title":"Advertising-vans"},
            {"title":"Aerosols"},
            {"title":"Agricultural chemicals"},
            {"title":"Agricultural contractors"},
            {"title":"Agricultural machines & supplies - Mfg. & Whol."},
            {"title":"Agricultural machines & supplies - Retail"},
            {"title":"Air conditioning - Installation"},
            {"title":"Air conditioning - Mfg. & Whol."},
            {"title":"Air freight service"},
            {"title":"Air-channels - Mfg. & Whol."},
            {"title":"Aircleaning equipment & supplies"},
            {"title":"Airlines"},
            {"title":"Airplanes & airplane equipment"},
            {"title":"Airport transport"},
            {"title":"Airports"},
            {"title":"Alarm systems - Installation"},
            {"title":"Alarm systems - Mfg. & Whol."},
            {"title":"Albums"},
            {"title":"All terrain vehicles"},
            {"title":"Alternative treatments & therapies"},
            {"title":"Aluminium & alloys"},
            {"title":"Aluminium - Constructions"},
            {"title":"Ambulance services"},
            {"title":"Amusement machines"},
            {"title":"Animal clinics"},
            {"title":"Animal protection societies"},
            {"title":"Animal shelters"},
            {"title":"Antennas"},
            {"title":"Antiquarian book trades"},
            {"title":"Antiques"},
            {"title":"Antiques restoration"},
            {"title":"Apiculture & accessories"},
            {"title":"Aquariums & accessories"},
            {"title":"Architects"},
            {"title":"Architectural concrete"},
            {"title":"Archives - Destruction & conservation"},
            {"title":"Army surplus stores"},
            {"title":"Art & decorative joineries"},
            {"title":"Art foundry work"},
            {"title":"Artists - Painters"},
            {"title":"Asbestos removal"},
            {"title":"Asphalt & tar"},
            {"title":"Asphalt contractors"},
            {"title":"Assembly works"},
            {"title":"Assessors - Automobiles"},
            {"title":"Astrology"},
            {"title":"Auction rooms"},
            {"title":"Auctions - Fruits, greens, flowers, etc"},
            {"title":"Audio-visual material"},
            {"title":"Audio-visual realizations"},
            {"title":"Auditors"},
            {"title":"Automatic towel & soap dispensers"},
            {"title":"Automobile - Adaptations for handicapped"},
            {"title":"Automobile - Air conditioning equipment"},
            {"title":"Automobile - Alarm systems"},
            {"title":"Automobile - Breakdown recovery"},
            {"title":"Automobile - Chauffeur driven car rental"},
            {"title":"Automobile - Diagnostic centres"},
            {"title":"Automobile - Driving proficiency schools"},
            {"title":"Automobile - Electrical service"},
            {"title":"Automobile - Enamels"},
            {"title":"Automobile - Glass"},
            {"title":"Automobile - Import, export & transit"},
            {"title":"Automobile - Independent distributors"},
            {"title":"Automobile - Inspection & examination centres"},
            {"title":"Automobile - L.P.G. systems"},
            {"title":"Automobile - Maintenance & cleaning products"},
            {"title":"Automobile - Navigation systems"},
            {"title":"Automobile - Parts & Access. - Mfg. & Whol."},
            {"title":"Automobile - Parts & Access. - Retail"},
            {"title":"Automobile - Radiators"},
            {"title":"Automobile - Radios"},
            {"title":"Automobile - Roofs & sunroofs"},
            {"title":"Automobile - Self-drive car rental"},
            {"title":"Automobile - Shock absorbers"},
            {"title":"Automobile - Tuning"},
            {"title":"Automobile - Upholstering & covers"},
            {"title":"Automobile - Wrecking"},
            {"title":"Automobile bodies - Construction"},
            {"title":"Automobile body equipment & supplies"},
            {"title":"Automobile concessionaires"},
            {"title":"Automobile dealers - Used cars"},
            {"title":"Automobile driving schools - Officially recognized"},
            {"title":"Automobile racing & accessories"},
            {"title":"Automobile-body repair"},
            {"title":"Automobiles & trucks - Brakes"},
            {"title":"Automobiles & trucks - Gear-boxes"},
            {"title":"Automobiles & trucks - Mfg. & assembling"},
            {"title":"Automobiles & trucks - Washing equipment"},
            {"title":"Automobiles - Washing"},
            {"title":"Baby & children's foods"},
            {"title":"Baby accessories - Mfg. & Whol."},
            {"title":"Baby accessories - Retail"},
            {"title":"Bags (Paper)"},
            {"title":"Bags (Plastics)"},
            {"title":"Bailiffs"},
            {"title":"Bakers & confectioners"},
            {"title":"Bakers - Equipment & Supplies"},
            {"title":"Bakers - Ingredients"},
            {"title":"Ballet schools"},
            {"title":"Balloon trips"},
            {"title":"Balloons"},
            {"title":"Balustrades"},
            {"title":"Banks"},
            {"title":"Banners"},
            {"title":"Baptismal articles"},
            {"title":"Barbecues & accessories"},
            {"title":"Barcode scanners & printers"},
            {"title":"Barrels & casks"},
            {"title":"Bars"},
            {"title":"Baskets - Mfg. & Whol."},
            {"title":"Bathing- & beachwear"},
            {"title":"Bathroom installation & accessories"},
            {"title":"Batteries (Dry)"},
            {"title":"Bearings"},
            {"title":"Beauty salons"},
            {"title":"Beauty salons - Products & apparatus"},
            {"title":"Beds & mattresses - Retail"},
            {"title":"Beds - Mfg.& Whol"},
            {"title":"Beer & ale - Dealers"},
            {"title":"Bell-foundries & tower-clocks"},
            {"title":"Belts"},
            {"title":"Bicycle racks"},
            {"title":"Bicycles - Mfg. & Whol."},
            {"title":"Bicycles - Retail"},
            {"title":"Bicycles, mopeds & scooters - Parts & Access. - Mfg. & Whol."},
            {"title":"Billiard - Equipment & Supplies"},
            {"title":"Biotechnology - Equipment, installations & products"},
            {"title":"Birds & supplies"},
            {"title":"Biscuits & cookies - Mfg. & Whol."},
            {"title":"Blacksmiths"},
            {"title":"Blankets & eiderdowns - Mfg. & Whol."},
            {"title":"Blankets & eiderdowns - Retail"},
            {"title":"Blast cleaning"},
            {"title":"Blast cleaning equipment & supplies"},
            {"title":"Blood transfusion services & centres"},
            {"title":"Boarding schools"},
            {"title":"Boiler cleaning"},
            {"title":"Boiler manufacturers"},
            {"title":"Boilers - Cleaning & Mainten."},
            {"title":"Boilers - Heating & steam"},
            {"title":"Bolts & nuts"},
            {"title":"Bookbinding"},
            {"title":"Bookbinding - Equipment & Supplies"},
            {"title":"Bookkeepers & tax specialists"},
            {"title":"Bookkeeping service"},
            {"title":"Bookmakers"},
            {"title":"Booksellers"},
            {"title":"Bottle caps, crowncorks & stoppers"},
            {"title":"Bottles & beakers"},
            {"title":"Boutiques"},
            {"title":"Bowling & skittle alleys"},
            {"title":"Boxes"},
            {"title":"Brakes, clutches & couplings - Industrial"},
            {"title":"Breweries"},
            {"title":"Breweries - Equipment & Supplies"},
            {"title":"Breweries - Ingredients"},
            {"title":"Brick - Mfg."},
            {"title":"Bronze"},
            {"title":"Brushes - Mfg. & Whol."},
            {"title":"Building - Framework"},
            {"title":"Building managers"},
            {"title":"Building materials"},
            {"title":"Building materials (Rustic) - Recuperation & sale"},
            {"title":"Building signalling"},
            {"title":"Building site-huts, caravans & containers"},
            {"title":"Building specialities"},
            {"title":"Buildings (Prefabricated) - Modular"},
            {"title":"Buildings - Metal"},
            {"title":"Bunkering"},
            {"title":"Burners (Industrial & domestic)"},
            {"title":"Buses & coaches - Charter & rental"},
            {"title":"Buses & coaches - Mfg. & sales"},
            {"title":"Butchers"},
            {"title":"Butter & Margarine - Mfg. & Whol."},
            {"title":"CD's & DVD's - Mfg. & Whol."},
            {"title":"CD's & DVD's - Retail"},
            {"title":"Cable reeling equipment"},
            {"title":"Cables & wires - Steel"},
            {"title":"Cafes - Installation & equipments"},
            {"title":"Cafetarias & selfservice restaurants"},
            {"title":"Calculators"},
            {"title":"Calendars"},
            {"title":"Camping equipment"},
            {"title":"Camping sites"},
            {"title":"Candles - Mfg. & Whol"},
            {"title":"Candy"},
            {"title":"Caravans & accessories"},
            {"title":"Carbonic acid"},
            {"title":"Carburettors"},
            {"title":"Cardboard & corrugated cardboard - Mfg. & Whol."},
            {"title":"Cardboard Boxes"},
            {"title":"Carpenters"},
            {"title":"Case & crate makers"},
            {"title":"Cash registers"},
            {"title":"Casings"},
            {"title":"Casinos"},
            {"title":"Cast iron"},
            {"title":"Caterers"},
            {"title":"Cattle-breeding materials"},
            {"title":"Ceilings"},
            {"title":"Cement"},
            {"title":"Ceramics"},
            {"title":"Ceramics & pottery - Materials & supplies"},
            {"title":"Ceremony cars"},
            {"title":"Chains & chain drives"},
            {"title":"Chair-cleaning"},
            {"title":"Chairs & tables - Mfg. & Whol."},
            {"title":"Chairs - Invalid"},
            {"title":"Chalk - Industrial"},
            {"title":"Chambers of commerce & industry"},
            {"title":"Champagne - Agents & importers"},
            {"title":"Chandeliers & lampshades - Mfg. & Whol."},
            {"title":"Chandeliers & lampshades - Retail"},
            {"title":"Charcoal"},
            {"title":"Charitable organisations"},
            {"title":"Cheese - Mfg. & Whol."},
            {"title":"Cheese - Retail"},
            {"title":"Chemicals - Mfg. & Whol"},
            {"title":"Chemists"},
            {"title":"Chemists - Equipment & Supplies"},
            {"title":"Children's animation"},
            {"title":"Chimney builders"},
            {"title":"Chimney sweeps"},
            {"title":"Chinaware & crockery - Mfg. & Whol."},
            {"title":"Chiropodists"},
            {"title":"Chiropractors"},
            {"title":"Chocolate - Mfg. & Whol."},
            {"title":"Chocolate goods & confectionery - Retail"},
            {"title":"Chocolate goods - Mfg. & Whol."},
            {"title":"Christmas & easter goods"},
            {"title":"Chromium & nickel plating"},
            {"title":"Church furnishings & supplies"},
            {"title":"Cigars, cigarettes & tobacco - Mfg., Whol., brokers"},
            {"title":"Cigars, cigarettes & tobacco - Retail"},
            {"title":"Cinemas"},
            {"title":"City planners"},
            {"title":"Civil engineering - Material"},
            {"title":"Claim & average adjusters"},
            {"title":"Clean rooms - Installation & Supplies"},
            {"title":"Cleaners & dyers"},
            {"title":"Cleaning & maintenance products"},
            {"title":"Cleaning - Industrial"},
            {"title":"Cleaning cloths"},
            {"title":"Cloakrooms - Installation"},
            {"title":"Clogs - Mfg. & Whol."},
            {"title":"Clothes - Hangers & racks"},
            {"title":"Clothing (Industrial) - Mfg. & sales"},
            {"title":"Clothing (Industrial) - Rental & Repair"},
            {"title":"Clothing (Ready-made) - Access., Supplies & material"},
            {"title":"Clothing (Ready-made) - Mfg. & Whol."},
            {"title":"Clothing (Second-hand) - Purchase & sale"},
            {"title":"Clothing - Children"},
            {"title":"Clothing - Gentlemen"},
            {"title":"Clothing - Haute couture"},
            {"title":"Clothing - Ladies"},
            {"title":"Clothing - Large sale-rooms"},
            {"title":"Clothing - Leather & buckskin"},
            {"title":"Clothing - Repair & alteration"},
            {"title":"Clubs for meeting & relaxation"},
            {"title":"Coaching"},
            {"title":"Coal"},
            {"title":"Coffee - Retail"},
            {"title":"Coffee - Roasting houses"},
            {"title":"Coffee - Whol. & brokers"},
            {"title":"Coffee-makers"},
            {"title":"Coffins"},
            {"title":"Coins & medals"},
            {"title":"Colour & style consultants"},
            {"title":"Commercial agencies"},
            {"title":"Commercial catering"},
            {"title":"Communication consultants"},
            {"title":"Composite materials"},
            {"title":"Compressors"},
            {"title":"Computer cabling"},
            {"title":"Computer graphics"},
            {"title":"Computer maintenance & Repair"},
            {"title":"Computer security"},
            {"title":"Computers"},
            {"title":"Computers - Rental"},
            {"title":"Computers - Used"},
            {"title":"Concern centres"},
            {"title":"Concrete - Glass"},
            {"title":"Concrete - Ready mixed"},
            {"title":"Concrete machines & installations"},
            {"title":"Concrete products"},
            {"title":"Concrete reinforcement"},
            {"title":"Concrete repairing"},
            {"title":"Concrete works"},
            {"title":"Confectioners - Mfg. & Whol."},
            {"title":"Congresses (Organization of)"},
            {"title":"Consulting engineers"},
            {"title":"Consumer information"},
            {"title":"Contact lenses"},
            {"title":"Container parks"},
            {"title":"Containers - Handling"},
            {"title":"Containers - Mfg & Repair"},
            {"title":"Containers for freight transport - Rental"},
            {"title":"Containers for waste & dumping (Rental & transport)"},
            {"title":"Continuous forms"},
            {"title":"Contractors (Materials & equipment for)"},
            {"title":"Contractors - Alterations"},
            {"title":"Contractors - Civil engineering works"},
            {"title":"Contractors - Demolition"},
            {"title":"Contractors - Foundation"},
            {"title":"Contractors - Groundwork"},
            {"title":"Contractors - House building"},
            {"title":"Contractors - Industrial & utility building"},
            {"title":"Contractors - Landscape"},
            {"title":"Contractors - Marine"},
            {"title":"Contractors - Restoration"},
            {"title":"Contractors - Road building"},
            {"title":"Contractors - Roofing"},
            {"title":"Contractors - Sewing"},
            {"title":"Contractors - Timber building"},
            {"title":"Contractors - Tuck pointing"},
            {"title":"Contractors - Turnkey industrial plants"},
            {"title":"Control organisations"},
            {"title":"Controls (Programmable)"},
            {"title":"Conveyor systems (Internal)"},
            {"title":"Conveyor systems (Pneumatic)"},
            {"title":"Cooling towers"},
            {"title":"Copies"},
            {"title":"Copy machines & supplies"},
            {"title":"Copyright services"},
            {"title":"Copywriters"},
            {"title":"Cork"},
            {"title":"Costumes - Theatrical"},
            {"title":"Counters & meters"},
            {"title":"Courier services"},
            {"title":"Cranes"},
            {"title":"Cranes - Rental"},
            {"title":"Credit cards - Issuing companies"},
            {"title":"Crematories"},
            {"title":"Crushing machines & installations"},
            {"title":"Crystal & glassware - Mfg. & Whol."},
            {"title":"Crystal & glassware - Retail"},
            {"title":"Cultural organizations"},
            {"title":"Cupolas - Skylight"},
            {"title":"Curiosity shops & clearers of furniture"},
            {"title":"Curtains & Curtain Fixtures - Mfg. & Whol"},
            {"title":"Curtains & curtain fixtures - Retail"},
            {"title":"Curtains (Cleaning of)"},
            {"title":"Cushions - Mfg. & Whol."},
            {"title":"Custom cutting of paper & cardboard"},
            {"title":"Custom packing"},
            {"title":"Customs consultants"},
            {"title":"Cutlery sets - Whol."},
            {"title":"Cutting tools"},
            {"title":"Dairy products - Mfg. & Whol."},
            {"title":"Dairy products - Retail"},
            {"title":"Dance supplies"},
            {"title":"Dancing schools"},
            {"title":"Data carriers - Supplies & Access."},
            {"title":"Data communications"},
            {"title":"Data processing - Computer cleaning"},
            {"title":"Data processing - Service offices"},
            {"title":"Data processing - Training"},
            {"title":"Day nurseries & baby sitting services"},
            {"title":"Debt recovery"},
            {"title":"Decorations for exhibitions & parties"},
            {"title":"Delicatessens"},
            {"title":"Delivery & messenger services"},
            {"title":"Delivery vans - Concessionaires"},
            {"title":"Demonstration sale - Companies"},
            {"title":"Dental clinics"},
            {"title":"Dental laboratories"},
            {"title":"Dentists"},
            {"title":"Dentists & dental technicians - Equipment & Supplies"},
            {"title":"Desktop publishing - Service offices"},
            {"title":"Dessert preparations - Mfg. & Whol."},
            {"title":"Detective agencies"},
            {"title":"Diamond - Dealers"},
            {"title":"Diamond - Mfg. & artisans"},
            {"title":"Diamond - Services"},
            {"title":"Diamond brokers"},
            {"title":"Diamond machines & tools"},
            {"title":"Diamond tools"},
            {"title":"Diamonds (Industrial)"},
            {"title":"Dictating machines"},
            {"title":"Dietary, health & biological food - Mfg. & Whol."},
            {"title":"Dietary, health & biological food - Retail"},
            {"title":"Dietitians"},
            {"title":"Dinner services & cutlery sets - Retail"},
            {"title":"Direct mail - Lettershops & mailing"},
            {"title":"Direct marketing - Computer services"},
            {"title":"Direct marketing - Consultants"},
            {"title":"Disc-jockeys"},
            {"title":"Discotheques"},
            {"title":"Dishwashing machines (Industrial)"},
            {"title":"Disinfectants & insecticides"},
            {"title":"Display cabinets"},
            {"title":"Display designers"},
            {"title":"Disposables"},
            {"title":"Distilleries"},
            {"title":"Distribution services - Brochures & samples"},
            {"title":"Distribution services - Physical distribution"},
            {"title":"Do-it-yourself - Articles & materials"},
            {"title":"Dock equipment"},
            {"title":"Dock warehousemen"},
            {"title":"Documents destruction machines"},
            {"title":"Dog - Breeding & selling"},
            {"title":"Dog training"},
            {"title":"Dogs & cats - Grooming"},
            {"title":"Domiciliary care"},
            {"title":"Domotics"},
            {"title":"Door & gate openers (Automatic)"},
            {"title":"Doors"},
            {"title":"Doors - Folding & sliding"},
            {"title":"Doors - Sectional & overhead type"},
            {"title":"Double glazing"},
            {"title":"Drain pipes (Unblocking)"},
            {"title":"Draining works"},
            {"title":"Drawing offices"},
            {"title":"Drawing, painting & graphic arts - Supplies"},
            {"title":"Dressing (Antiseptic) - Mfg. & Whol."},
            {"title":"Drilling & sawing in reinforced concrete"},
            {"title":"Drug stores"},
            {"title":"Dry cleaners & dyers - Machinery & equipment"},
            {"title":"Drying equipment - Industrial"},
            {"title":"Ducts & ducting (Underground)"},
            {"title":"Dumps"},
            {"title":"Dumptrucks"},
            {"title":"Dust removing equipment"},
            {"title":"Dust removing services"},
            {"title":"Dustbins & dustbagholders"},
            {"title":"Dyestuff"},
            {"title":"Ecological construction"},
            {"title":"Economic institutions & development companies"},
            {"title":"Education (Higher)"},
            {"title":"Education (Primary)"},
            {"title":"Education (Secondary)"},
            {"title":"Education - Private schools"},
            {"title":"Eggs - Whol."},
            {"title":"Electric appliances - Mainten., Repair & Retail"},
            {"title":"Electric appliances - Mfg. & Whol."},
            {"title":"Electric appliances - Second-hand"},
            {"title":"Electric cables & wires"},
            {"title":"Electric coil winding"},
            {"title":"Electric components"},
            {"title":"Electric condensers"},
            {"title":"Electric contractors - General"},
            {"title":"Electric contractors - Housewiring"},
            {"title":"Electric contractors - Industrial"},
            {"title":"Electric generators"},
            {"title":"Electric machines & supplies - Industrial"},
            {"title":"Electric resistors"},
            {"title":"Electric supplies - Mfg. & Whol."},
            {"title":"Electric supplies - Retail"},
            {"title":"Electric transformers, rectifiers, converters"},
            {"title":"Electrical engineering - Consultants"},
            {"title":"Electrical heating"},
            {"title":"Electrical vehicles"},
            {"title":"Electricity - Cabling & wiring"},
            {"title":"Electricity - High & low voltage cabins"},
            {"title":"Electricity supply & gas companies"},
            {"title":"Electromechanical components"},
            {"title":"Electronic filing"},
            {"title":"Electronics - Components"},
            {"title":"Electronics - Equipment"},
            {"title":"Electronics - Marine"},
            {"title":"Elevators"},
            {"title":"Embassies & consulates"},
            {"title":"Embroidery & pleating"},
            {"title":"Employment agencies"},
            {"title":"Energy experts"},
            {"title":"Energy saving & recuperation"},
            {"title":"Engineering offices - Civil engineering"},
            {"title":"Engineering offices - Environmental techniques"},
            {"title":"Engineering offices - General"},
            {"title":"Engineering offices - Industrial automation"},
            {"title":"Engineering offices - Lighting"},
            {"title":"Engineering offices - Technical equipment"},
            {"title":"Engineering workshops"},
            {"title":"Engines - Diesel"},
            {"title":"Engines - Gasoline"},
            {"title":"Engraving"},
            {"title":"Engraving machines & supplies"},
            {"title":"Entertainers"},
            {"title":"Envelopes"},
            {"title":"Environment - Organisations"},
            {"title":"Environmental pollution control"},
            {"title":"Ergotherapists"},
            {"title":"Erotic articles"},
            {"title":"Erotic massage"},
            {"title":"Erotic saunas"},
            {"title":"Erotic spectacles"},
            {"title":"Escape Rooms"},
            {"title":"Etchings, pictures, engravings"},
            {"title":"Events (Organisation of)"},
            {"title":"Exchange brokers - Belgian"},
            {"title":"Exchange offices"},
            {"title":"Exhaust systems"},
            {"title":"Exhibition stands"},
            {"title":"Explosion proof equipment"},
            {"title":"Explosives"},
            {"title":"Express freight transport"},
            {"title":"Extraction equipment - Gas & smoke"},
            {"title":"Extractor hoods"},
            {"title":"Extracts & flavours - Mfg. & Whol."},
            {"title":"Eyes (Artificial)"},
            {"title":"Fabrics & silks - Mfg. & Whol."},
            {"title":"Fabrics & silks - Retail"},
            {"title":"Facilities management"},
            {"title":"Facing bricks"},
            {"title":"Factoring"},
            {"title":"Fair attractions - Sale & hiring"},
            {"title":"Fairs, shows, exhibitions"},
            {"title":"Farriers"},
            {"title":"Fashion designers & stylists"},
            {"title":"Fashion show organizers"},
            {"title":"Facade cleaning & maintenance"},
            {"title":"Facade covering"},
            {"title":"Feathers"},
            {"title":"Federal public services & ministries"},
            {"title":"Felt"},
            {"title":"Fences"},
            {"title":"Fertilizers - Merchants"},
            {"title":"Fertilizers - Mfg."},
            {"title":"Fidelity cards - Issuing companies"},
            {"title":"Fiduciairies"},
            {"title":"Files & binders"},
            {"title":"Filing systems"},
            {"title":"Filling & sealing machines"},
            {"title":"Film hiring & distribution"},
            {"title":"Film producers & realizators"},
            {"title":"Filter cloths & felts"},
            {"title":"Filters - Air & gas"},
            {"title":"Filters - Liquid"},
            {"title":"Financial groups"},
            {"title":"Financing & credit companies"},
            {"title":"Fire & water damage restoration"},
            {"title":"Fire detection materials - Installation"},
            {"title":"Fire detection materials - Mfg. & Whol."},
            {"title":"Fire extinguishers"},
            {"title":"Fire protection"},
            {"title":"Fire protection equipment"},
            {"title":"Fireplaces - Ornamental & open"},
            {"title":"Fireproof & armoured doors"},
            {"title":"Firewood"},
            {"title":"Fireworks"},
            {"title":"Fish & shellfish - Retail"},
            {"title":"Fish & shellfish - Whol."},
            {"title":"Fish breeding"},
            {"title":"Fish smokers & curers"},
            {"title":"Fisheries & equipment"},
            {"title":"Fishing tackle"},
            {"title":"Fitness & gymnastics - Apparatus & Access."},
            {"title":"Fitness centres"},
            {"title":"Fittings"},
            {"title":"Fixing materials"},
            {"title":"Flags & banners"},
            {"title":"Flax"},
            {"title":"Flockprint"},
            {"title":"Floor armoring"},
            {"title":"Floor coverings - Mfg. & Whol."},
            {"title":"Floor coverings - Retail"},
            {"title":"Floor elements (Prefab)"},
            {"title":"Floor heating"},
            {"title":"Floor layers & mosaicists"},
            {"title":"Floors (Marble & natural stone) - Mainten. & Repair"},
            {"title":"Floors (Metal)"},
            {"title":"Floors (Raised)"},
            {"title":"Floors - Industrial"},
            {"title":"Florists"},
            {"title":"Florists - Supplies"},
            {"title":"Flowers & plants (Artificial)"},
            {"title":"Flowers & plants - Decoration"},
            {"title":"Flowers & plants - Growers & Whol."},
            {"title":"Flues"},
            {"title":"Flying schools"},
            {"title":"Foam rubber"},
            {"title":"Food & non-food - Delivered"},
            {"title":"Food industry - Ingredients"},
            {"title":"Food manufacturing equipment"},
            {"title":"Food products - Mfg. & Whol."},
            {"title":"Foods - Frozen - Mfg. & Whol."},
            {"title":"Foods - Frozen - Retail"},
            {"title":"Forestries"},
            {"title":"Forestry equipment"},
            {"title":"Fork lift trucks"},
            {"title":"Formal wear, bridal & communion outfits - Mfg. & sales"},
            {"title":"Formal wear, bridal & communion outfits - Rental"},
            {"title":"Formwork & shuttering"},
            {"title":"Forwarding agencies"},
            {"title":"Foundries"},
            {"title":"Foundries - Supplies"},
            {"title":"Fountains"},
            {"title":"Frames"},
            {"title":"Franchising"},
            {"title":"Free local papers"},
            {"title":"Fried Potatoes Stands"},
            {"title":"Fried potatoes stands - Supplies & equipment"},
            {"title":"Fried potatoes stands specialities & snack products"},
            {"title":"Fruit & vegetables (Canned) - Mfg. & Whol."},
            {"title":"Fruit & vegetables - Growers"},
            {"title":"Fruit & vegetables - Import & export"},
            {"title":"Fruit & vegetables - Retail"},
            {"title":"Fruit & vegetables - Whol."},
            {"title":"Fruit (Dried)"},
            {"title":"Fruit juices & syrups"},
            {"title":"Fuel oils"},
            {"title":"Fuels"},
            {"title":"Funeral directors"},
            {"title":"Funeral directors - Supplies"},
            {"title":"Fur, leather & buckskin - Cleaning & Repair"},
            {"title":"Furniture - Antique"},
            {"title":"Furniture - Armchairs"},
            {"title":"Furniture - Art"},
            {"title":"Furniture - Collectivities"},
            {"title":"Furniture - Custom made"},
            {"title":"Furniture - Design"},
            {"title":"Furniture - Garden"},
            {"title":"Furniture - General"},
            {"title":"Furniture - Hospital"},
            {"title":"Furniture - Juvenile"},
            {"title":"Furniture - Kitchen"},
            {"title":"Furniture - Office"},
            {"title":"Furniture - Rental"},
            {"title":"Furniture - School"},
            {"title":"Furniture - Sectional"},
            {"title":"Furniture - Small items"},
            {"title":"Furniture - Storage"},
            {"title":"Furniture - Used"},
            {"title":"Furs"},
            {"title":"GSM - Apparatus & Accessories"},
            {"title":"GSM - Services"},
            {"title":"Galleries"},
            {"title":"Games computers & video games"},
            {"title":"Garages"},
            {"title":"Garages - Equipment & Supplies"},
            {"title":"Garages - Prefabricated"},
            {"title":"Garages for trucks - Mainten. & Repair"},
            {"title":"Garden & horticultural machines"},
            {"title":"Garden centres"},
            {"title":"Garden decoration"},
            {"title":"Garden equipment"},
            {"title":"Garden houses"},
            {"title":"Garden lighting"},
            {"title":"Garden wood"},
            {"title":"Gardening & garden maintenance"},
            {"title":"Gardening supplies"},
            {"title":"Gas - Household"},
            {"title":"Gas - Industrial"},
            {"title":"Gas - Medical"},
            {"title":"Gas appliances - Retail & Mainten."},
            {"title":"Gas detection"},
            {"title":"Gears"},
            {"title":"Genealogists"},
            {"title":"Gifts - Mfg. & Whol."},
            {"title":"Gifts - Retail"},
            {"title":"Glass & porcelain decoration"},
            {"title":"Glass - Stained & glaziers"},
            {"title":"Glass - Window & plate - Mfg. & Whol."},
            {"title":"Glass blowers"},
            {"title":"Glass fibre"},
            {"title":"Glass working machines & tools"},
            {"title":"Glassware - Industrial"},
            {"title":"Glaziers"},
            {"title":"Gloves - Mfg. & Whol"},
            {"title":"Glues"},
            {"title":"Gold & silver plating"},
            {"title":"Gold - Purchase & sale"},
            {"title":"Grains & seeds (Agriculture & horticulture) - Whol & brokers"},
            {"title":"Grandstands & stages - Mfg & rental"},
            {"title":"Graphic designers"},
            {"title":"Graphite"},
            {"title":"Graphologists"},
            {"title":"Gratings"},
            {"title":"Grease - Melting"},
            {"title":"Greenhouses"},
            {"title":"Greeting cards & picture postcards"},
            {"title":"Grocers"},
            {"title":"Groundwater sinking"},
            {"title":"Groundwork - Machines & material"},
            {"title":"Guides"},
            {"title":"Guns & ammunition - Mfg. & Whol."},
            {"title":"Guns & ammunition - Retail"},
            {"title":"Haberdashery - Mfg. & Whol"},
            {"title":"Haberdashery - Retail"},
            {"title":"Hair care goods"},
            {"title":"Hair treatment"},
            {"title":"Hairdressing salons - Children"},
            {"title":"Hairdressing salons - Equipment & Supplies"},
            {"title":"Hairdressing salons - Gentlemen"},
            {"title":"Hairdressing salons - Ladies"},
            {"title":"Halls - Inflatable"},
            {"title":"Halls - Rental"},
            {"title":"Hand pallet trucks"},
            {"title":"Handicapped - Services & centres"},
            {"title":"Handicraft"},
            {"title":"Handtrucks & barrows"},
            {"title":"Hangars & open sheds"},
            {"title":"Hardware - Builders' & furniture - Mfg. & Whol."},
            {"title":"Hardware - Builders' & furniture - Retail"},
            {"title":"Hardware - Mfg. & Whol"},
            {"title":"Hardware - Retail"},
            {"title":"Hatcheries - Poultry"},
            {"title":"Hats & caps - Men"},
            {"title":"Health centers"},
            {"title":"Health resorts"},
            {"title":"Hearing aids"},
            {"title":"Heat exchangers"},
            {"title":"Heat pumps"},
            {"title":"Heating (Central) - Installation"},
            {"title":"Heating (Central) - Mainten. & Repair"},
            {"title":"Heating (Central) - Mfg. & Whol."},
            {"title":"Heating (Industrial)"},
            {"title":"Heating - Warm air"},
            {"title":"Heating apparatus"},
            {"title":"Helicopters & aircrafts - Rental"},
            {"title":"Herbs - Medicinal"},
            {"title":"Hi-fi & Stereophonic equipment - Mfg. & Whol."},
            {"title":"Hi-fi & stereophonic equipment - Retail"},
            {"title":"Hides & skins"},
            {"title":"Hoisting- & lashingbelts"},
            {"title":"Holiday accomodation - Rental"},
            {"title":"Holiday farms & guest houses"},
            {"title":"Home study tuition"},
            {"title":"Homeopathy"},
            {"title":"Homes for children"},
            {"title":"Honey - Whol."},
            {"title":"Horse dealers"},
            {"title":"Horseriding & supplies"},
            {"title":"Horticultural automation"},
            {"title":"Hoses (Flexible metal)"},
            {"title":"Hoses (Rubber & plastic)"},
            {"title":"Hosiery"},
            {"title":"Hospitals"},
            {"title":"Hospitals - Equipment & Access."},
            {"title":"Hostesses"},
            {"title":"Hot air generators"},
            {"title":"Hotel & catering supplies"},
            {"title":"Hotels"},
            {"title":"Hotels - Installation & equipment"},
            {"title":"Hotels - International reservations"},
            {"title":"Household linen - Mfg. & Whol."},
            {"title":"Household linen - Retail"},
            {"title":"Houses (Prefabricated)"},
            {"title":"Housewares - Mfg. & Whol"},
            {"title":"Housewares - Retail"},
            {"title":"Humidifiers & dehydrating apparatus"},
            {"title":"Hunting & supplies"},
            {"title":"Hydraulics"},
            {"title":"Hygienic articles - Mfg. & Whol."},
            {"title":"ICT-Consultants"},
            {"title":"Ice - Mfg. & distributors"},
            {"title":"Ice cream"},
            {"title":"Ice making machines"},
            {"title":"Ice-cream parlours"},
            {"title":"Ice-cream parlours - Supplies"},
            {"title":"Identification cards - Mfg. & Whol."},
            {"title":"Illuminations"},
            {"title":"Illustrators"},
            {"title":"Imitation jewelry - Mfg.& Whol."},
            {"title":"Imitation jewelry - Retail"},
            {"title":"Import - Export"},
            {"title":"Incentives"},
            {"title":"Incinerators"},
            {"title":"Industrial automation"},
            {"title":"Industrial design"},
            {"title":"Industrial kitchens & restaurants (Catering for)"},
            {"title":"Industrial kitchens - Installation & equipment"},
            {"title":"Industrial kitchens - Supplies"},
            {"title":"Industrial research centres"},
            {"title":"Industrial safety & hygiene consultants"},
            {"title":"Injection pumps - Diesel & petrol"},
            {"title":"Insect screens"},
            {"title":"Insulation - Electric"},
            {"title":"Insulation materials (Acoustical)"},
            {"title":"Insulation materials (Thermical)"},
            {"title":"Insulation works (Thermical & acoustical)"},
            {"title":"Insurance agents"},
            {"title":"Insurance brokers"},
            {"title":"Insurances - Companies & representatives"},
            {"title":"Intercoms & videophones"},
            {"title":"Interior decoration"},
            {"title":"Interior designers"},
            {"title":"International organisations"},
            {"title":"Internet - Access providers"},
            {"title":"Internet - Services"},
            {"title":"Internet - Webdesign"},
            {"title":"Interpreters"},
            {"title":"Investigators - Industrial & commercial"},
            {"title":"Investments"},
            {"title":"Iron merchants"},
            {"title":"Ironing machines - Mfg. & Whol."},
            {"title":"Ironworks"},
            {"title":"Irrigation systems"},
            {"title":"Jacks & cylinders"},
            {"title":"Jams, marmalades, syrups - Mfg. & Whol"},
            {"title":"Janitor service"},
            {"title":"Jeans & casual wear"},
            {"title":"Jewellers - Equipment & Supplies"},
            {"title":"Jewellers - Mfg. & Whol."},
            {"title":"Jewellers - Retail"},
            {"title":"Jewelry cases"},
            {"title":"Joints & packing materials"},
            {"title":"Juke Boxes"},
            {"title":"Jute"},
            {"title":"Karaoke equipment - Retail & rental"},
            {"title":"Karting & accessories"},
            {"title":"Kinesiology"},
            {"title":"Kitchen ranges"},
            {"title":"Kitchens"},
            {"title":"Knitting - Wool & yarn"},
            {"title":"Knitwear - Mfg. & Whol."},
            {"title":"Knives & blades - Industrial"},
            {"title":"Knives & scissors"},
            {"title":"Labeling machines"},
            {"title":"Labels"},
            {"title":"Laboratories (Medical)"},
            {"title":"Laboratories - General"},
            {"title":"Laboratories - Machines, apparatus & supplies"},
            {"title":"Laboratories - Study & installation"},
            {"title":"Lace"},
            {"title":"Ladders"},
            {"title":"Ladies needlework"},
            {"title":"Lamps - Mfg. & Whol."},
            {"title":"Land surveyors"},
            {"title":"Landscape architects"},
            {"title":"Language & sports holidays"},
            {"title":"Language schools"},
            {"title":"Laser welding & cutting"},
            {"title":"Lasers - Industrial"},
            {"title":"Laundries"},
            {"title":"Laundries - Machines & Supplies"},
            {"title":"Laundries - Self service"},
            {"title":"Law consultants"},
            {"title":"Lawn mowers"},
            {"title":"Lawyers"},
            {"title":"Lawyers - Foreign"},
            {"title":"Leaks (Detection of)"},
            {"title":"Leasing"},
            {"title":"Leather goods - Mfg. & Whol."},
            {"title":"Leather goods - Retail"},
            {"title":"Leisure articles - Mfg. & Whol."},
            {"title":"Leisure articles - Retail"},
            {"title":"Letters & lettering"},
            {"title":"Libraries"},
            {"title":"Libraries - Equipment"},
            {"title":"Lift tables"},
            {"title":"Lifting gear"},
            {"title":"Lighting - Fluorescent & neon"},
            {"title":"Lighting - Public & industrial"},
            {"title":"Lighting - Stage & disco"},
            {"title":"Lighting fixtures - Mfg. & Whol."},
            {"title":"Lighting fixtures - Rental"},
            {"title":"Lighting fixtures - Retail"},
            {"title":"Lightning protection"},
            {"title":"Linen rental"},
            {"title":"Lingerie & underwear - Retail"},
            {"title":"Lingerie - Mfg. & Whol."},
            {"title":"Livestock breeding"},
            {"title":"Livestock buyers"},
            {"title":"Livestock food - Mfg. & Whol."},
            {"title":"Livestock food - Retail"},
            {"title":"Local authorities"},
            {"title":"Local radios"},
            {"title":"Locksmiths"},
            {"title":"Logistic services"},
            {"title":"Lubricating compounds"},
            {"title":"Lubricating equipment"},
            {"title":"Luggage-carriers"},
            {"title":"Machine tools"},
            {"title":"Machinery builders"},
            {"title":"Machines, apparatus & tools (Private use) - Sales & rental"},
            {"title":"Machines, apparatus & tools (Professional use) - Sales & rental"},
            {"title":"Machines, apparatus & tools - Second-hand"},
            {"title":"Magnetic apparatus"},
            {"title":"Mail boxes"},
            {"title":"Mail order houses"},
            {"title":"Mailing machines & supplies"},
            {"title":"Maintenance & cleaning equipment (Industrial)"},
            {"title":"Maintenance works - Industrial"},
            {"title":"Make-up & grease paint"},
            {"title":"Make-up artists"},
            {"title":"Management & maintenance of technical installations"},
            {"title":"Management consultants"},
            {"title":"Maps & plans"},
            {"title":"Marble & granite works"},
            {"title":"Marble, granite & natural stone"},
            {"title":"Market & fried potatoes vans"},
            {"title":"Market research agencies"},
            {"title":"Market vendor's material"},
            {"title":"Marketing - Consultants"},
            {"title":"Marking & codifying - Apparatus & systems"},
            {"title":"Martial arts & accessories"},
            {"title":"Massage"},
            {"title":"Massage equipment & supplies"},
            {"title":"Masters of ceremonies"},
            {"title":"Masts & pylons"},
            {"title":"Matches - Mfg. & Whol"},
            {"title":"Material handling machines"},
            {"title":"Material testing"},
            {"title":"Materials Handling - Containers"},
            {"title":"Maternity wear"},
            {"title":"Matrices"},
            {"title":"Matrimonial agencies"},
            {"title":"Mats"},
            {"title":"Mattresses - Mfg. & Whol."},
            {"title":"Mayonnaise - Mfg. & Whol"},
            {"title":"Meal vouchers"},
            {"title":"Meals - Delivered"},
            {"title":"Measurers, weighers, estimators & gaugers"},
            {"title":"Measuring & control apparatus"},
            {"title":"Meat & meat products - Machines & Supplies"},
            {"title":"Meat (Canned) - Mfg. & Whol."},
            {"title":"Meat - Whol"},
            {"title":"Meat products - Mfg. & Whol."},
            {"title":"Medals & trophies"},
            {"title":"Media brokers"},
            {"title":"Media centres"},
            {"title":"Mediators"},
            {"title":"Medical centers"},
            {"title":"Medical imaging - Equipment & Supplies"},
            {"title":"Medical instruments & apparatus"},
            {"title":"Metal detectors"},
            {"title":"Metal polishing"},
            {"title":"Metal turning"},
            {"title":"Metal work - Ornamental"},
            {"title":"Metal working"},
            {"title":"Metal working machinery"},
            {"title":"Metallizing"},
            {"title":"Metals (Precious)"},
            {"title":"Metals - Non-ferrous"},
            {"title":"Microfilms & microfiches"},
            {"title":"Midwives"},
            {"title":"Military & police goods"},
            {"title":"Milking machines"},
            {"title":"Millers"},
            {"title":"Milliners"},
            {"title":"Mirrors"},
            {"title":"Miscellaneous"},
            {"title":"Mixing, dosing & agitating machinery"},
            {"title":"Mobile workshops - Installation & equipment"},
            {"title":"Model manufacturers"},
            {"title":"Models & modelling agencies"},
            {"title":"Models - Scale"},
            {"title":"Monuments - Memorial"},
            {"title":"Mopeds & scooters - Retail"},
            {"title":"Mortgages & loans"},
            {"title":"Moss removal"},
            {"title":"Motor saws"},
            {"title":"Motorbikes"},
            {"title":"Motorbikes - Clothing & equipment"},
            {"title":"Motorbikes - Rental"},
            {"title":"Motorhomes & accessories"},
            {"title":"Motors - Electric"},
            {"title":"Motors - Overhauling"},
            {"title":"Mouldings"},
            {"title":"Mountaineering & equipment"},
            {"title":"Mourning wreaths"},
            {"title":"Movie makers"},
            {"title":"Multimedia - Systems & productions"},
            {"title":"Museums"},
            {"title":"Mushrooms"},
            {"title":"Music - Entertainment & background"},
            {"title":"Music publishers"},
            {"title":"Music teachers - Private"},
            {"title":"Musical instruments - Mainten. & Repair"},
            {"title":"Musical instruments - Mfg. & Whol."},
            {"title":"Musical instruments - Rental"},
            {"title":"Musical instruments - Retail"},
            {"title":"Musicians (Freelance)"},
            {"title":"Mutualities"},
            {"title":"Nail studios"},
            {"title":"Name plates"},
            {"title":"Navigation schools"},
            {"title":"Networks"},
            {"title":"News agencies"},
            {"title":"Newspapers, magazines, periodicals - Distribution"},
            {"title":"Newspapers, magazines, periodicals - Publishers"},
            {"title":"Night shops"},
            {"title":"Nightclubs"},
            {"title":"Non-woven fabrics - Mfg. & Whol."},
            {"title":"Notaries"},
            {"title":"Nurserymen"},
            {"title":"Nurses - Private"},
            {"title":"Nursing homes"},
            {"title":"Office fixtures"},
            {"title":"Office machines - Mainten. & Repair"},
            {"title":"Office machines - Mfg. & Whol."},
            {"title":"Office machines - Retail & rental"},
            {"title":"Office supplies - Mfg. & Whol."},
            {"title":"Office supplies - Retail"},
            {"title":"Offices (Fully equipped) - Rental"},
            {"title":"Oil products"},
            {"title":"Oil refineries"},
            {"title":"Oils & Fats - Vegetable"},
            {"title":"Oils & fats - Animal"},
            {"title":"Oldtimers"},
            {"title":"Optical fibres"},
            {"title":"Optical instruments"},
            {"title":"Opticians - Mfg. & Whol"},
            {"title":"Opticians - Retail"},
            {"title":"Optometrists"},
            {"title":"Orchestras"},
            {"title":"Ores"},
            {"title":"Organs"},
            {"title":"Oriental carpets"},
            {"title":"Ornamental shutters"},
            {"title":"Orthodontists"},
            {"title":"Orthopedic appliances - Mfg. & Whol."},
            {"title":"Orthopedists"},
            {"title":"Orthoptists"},
            {"title":"Osteopaths"},
            {"title":"Outplacement consultants"},
            {"title":"Ovens - Industrial"},
            {"title":"Oxygen"},
            {"title":"P.M.S. - Centres"},
            {"title":"Packing (Marine & air transport)"},
            {"title":"Packing (Plastic)"},
            {"title":"Packing - Anti-shock"},
            {"title":"Packing - Design & realization"},
            {"title":"Packing - General"},
            {"title":"Packing - Metal"},
            {"title":"Packing - Paper & cardboard"},
            {"title":"Packing machinery"},
            {"title":"Paint removing products & apparatus"},
            {"title":"Paint spraying equipment & supplies"},
            {"title":"Paint spraying shops"},
            {"title":"Painters - Equipment & supplies"},
            {"title":"Painting - Industrial"},
            {"title":"Painting contractors"},
            {"title":"Paints, lacquers, enamels & varnishes - Mfg. & Whol."},
            {"title":"Paints, lacquers, enamels & varnishes - Retail"},
            {"title":"Pallets"},
            {"title":"Palliative care"},
            {"title":"Panels - Stratified"},
            {"title":"Paper - General"},
            {"title":"Paper - Hygiene"},
            {"title":"Paper hangers"},
            {"title":"Paper products - Mfg. & Whol."},
            {"title":"Parking garages"},
            {"title":"Parking-places & drives (Laying-out)"},
            {"title":"Parkinggarages - Installation & Access."},
            {"title":"Parquets"},
            {"title":"Parquets - Repair & Mainten."},
            {"title":"Partitions"},
            {"title":"Party goods"},
            {"title":"Party planning"},
            {"title":"Party supplies - Rental"},
            {"title":"Passport photos"},
            {"title":"Pasta"},
            {"title":"Patent & trademark agencies"},
            {"title":"Pattern makers"},
            {"title":"Pay terminals (Electronic)"},
            {"title":"Pearls"},
            {"title":"Peat products & soil"},
            {"title":"Pedicure"},
            {"title":"Pedicure - Supplies"},
            {"title":"Pellets"},
            {"title":"Pens & pencils"},
            {"title":"Pensions"},
            {"title":"Perfumery - Mfg. & Whol."},
            {"title":"Perfumery - Retail"},
            {"title":"Periodontists"},
            {"title":"Person detecting devices"},
            {"title":"Personnel recruitment & -selection"},
            {"title":"Personnel training"},
            {"title":"Pet cemeteries & crematories"},
            {"title":"Pet foods - Mfg. & Whol."},
            {"title":"Pet shops - Retail"},
            {"title":"Pet supplies & products - Mfg. & Whol."},
            {"title":"Pharmaceutical products & laboratories"},
            {"title":"Photo & slide libraries"},
            {"title":"Photoengravers"},
            {"title":"Photographers"},
            {"title":"Photographers - Aerial"},
            {"title":"Photographers - Portrait"},
            {"title":"Photographic & film laboratories"},
            {"title":"Photographic equipment & accessories - Mfg.& Whol"},
            {"title":"Photographic equipment & accessories - Retail & Repair"},
            {"title":"Photographic setting"},
            {"title":"Photography - Reporting"},
            {"title":"Physicians & surgeons"},
            {"title":"Physiotherapists"},
            {"title":"Pianos"},
            {"title":"Pianos - Tuning"},
            {"title":"Pictures & paintings - Purchase & sale"},
            {"title":"Pigeon - Fanciers & Access."},
            {"title":"Pigs"},
            {"title":"Pilots - Marine"},
            {"title":"Pipe pushing"},
            {"title":"Pipelines"},
            {"title":"Pipes - Cardboard"},
            {"title":"Pipes - General"},
            {"title":"Pipes - Plastic"},
            {"title":"Pizzas"},
            {"title":"Plans - Reproduction"},
            {"title":"Plants - Rental"},
            {"title":"Plaster"},
            {"title":"Plastic coating for metals"},
            {"title":"Plastic foam"},
            {"title":"Plastic surgery"},
            {"title":"Plasticizing & varnishing of documents"},
            {"title":"Plasticizing machines"},
            {"title":"Plastics - Building industry"},
            {"title":"Plastics - General"},
            {"title":"Plastics - Industrial"},
            {"title":"Plastics - Machinery"},
            {"title":"Plastics - Raw materials"},
            {"title":"Plastics working"},
            {"title":"Plates - Miscellaneous"},
            {"title":"Playground equipments"},
            {"title":"Playing cards & accessories - Mfg. & Whol."},
            {"title":"Plotters - Apparatus & Supplies"},
            {"title":"Plumbers"},
            {"title":"Plumbers - Equipment & Supplies"},
            {"title":"Plywood"},
            {"title":"Pneumatic automation"},
            {"title":"Pneumatic tools"},
            {"title":"Pneumatic tube systems"},
            {"title":"Point of sale publicity (P.O.P.)"},
            {"title":"Police"},
            {"title":"Polyester"},
            {"title":"Ponds - Laying-out & Access."},
            {"title":"Poolhouses"},
            {"title":"Port facilities"},
            {"title":"Potatoes - Whol."},
            {"title":"Pots & cachepots - Mfg. & Whol."},
            {"title":"Poultry & game - Retail"},
            {"title":"Poultry & game - Whol."},
            {"title":"Poultry & rabbit farms"},
            {"title":"Poultry & rabbit slaughterhouses"},
            {"title":"Pre-press"},
            {"title":"Precious stones & minerals"},
            {"title":"Precision engineering"},
            {"title":"Presses (Industrial)"},
            {"title":"Pressing & stamping works (Metal)"},
            {"title":"Printers"},
            {"title":"Printing (Digital)"},
            {"title":"Printing - General"},
            {"title":"Printing - Occasional"},
            {"title":"Printing - Offset"},
            {"title":"Printing - Relief"},
            {"title":"Printing - Silkscreen"},
            {"title":"Printing inks"},
            {"title":"Printing machines & equipment"},
            {"title":"Private banking"},
            {"title":"Private clubs"},
            {"title":"Professional & trade-union associations & federations"},
            {"title":"Professional training"},
            {"title":"Project developers"},
            {"title":"Psychologists"},
            {"title":"Psychomotor therapists"},
            {"title":"Psychotherapists & psychoanalysts"},
            {"title":"Public furniture"},
            {"title":"Public relations consultants"},
            {"title":"Public transport"},
            {"title":"Publishers"},
            {"title":"Pubs"},
            {"title":"Pumps - Industrial"},
            {"title":"Pumps - Water"},
            {"title":"Purchasing associations"},
            {"title":"Racks & storage systems"},
            {"title":"Radiator panelling"},
            {"title":"Radiators & convectors - Central heating"},
            {"title":"Radio telephony"},
            {"title":"Radio-amateurs - Apparatus & Access."},
            {"title":"Railroad equipment"},
            {"title":"Railroad works"},
            {"title":"Rainwaterrecuperation - Systems"},
            {"title":"Ready-made meals - Mfg. & Whol."},
            {"title":"Real estate - Abroad"},
            {"title":"Real estate - Belgium"},
            {"title":"Recreation parks"},
            {"title":"Recycling companies"},
            {"title":"Refractory materials"},
            {"title":"Refrigerating parts"},
            {"title":"Refrigeration equipment - Industrial & commercial"},
            {"title":"Refrigerators & freezers - Mfg. & Whol."},
            {"title":"Refrigerators & freezers - Retail"},
            {"title":"Refuse collecting vehicles"},
            {"title":"Regulating systems"},
            {"title":"Rehabiliation apparatus & aids"},
            {"title":"Religious articles"},
            {"title":"Relocation"},
            {"title":"Remote control systems"},
            {"title":"Removals"},
            {"title":"Removals (Material for) - Rental"},
            {"title":"Rendering companies"},
            {"title":"Rendezvous rooms - Rental"},
            {"title":"Renewable energy"},
            {"title":"Rental - Anti-dust mats"},
            {"title":"Rental - Cars, delivery vans & trucks - Long term"},
            {"title":"Repair of dentures"},
            {"title":"Repair services"},
            {"title":"Resins"},
            {"title":"Restaurants"},
            {"title":"Restaurants - Installation & equipment"},
            {"title":"Ribbons - Mfg. & Whol."},
            {"title":"Riding schools"},
            {"title":"Road & yard signalling"},
            {"title":"Road building materials"},
            {"title":"Roasting - Spits & ovens"},
            {"title":"Robots - Industrial"},
            {"title":"Roofing - Materials"},
            {"title":"Rooms (Furnished) - Rental"},
            {"title":"Ropes & twines"},
            {"title":"Rubber - Industrial"},
            {"title":"Rubber articles - Mfg. & Whol."},
            {"title":"Rubbish removal"},
            {"title":"Rugs & carpets - Cleaning"},
            {"title":"Rugs & carpets - Mfg. & Whol."},
            {"title":"Rugs & carpets - Repair & restoration"},
            {"title":"Rugs & carpets - Retail"},
            {"title":"Rust & corrosion prevention"},
            {"title":"Saddlers harness makers"},
            {"title":"Safes"},
            {"title":"Safety coordinators"},
            {"title":"Safety equipment - Individual & collective"},
            {"title":"Safety glass"},
            {"title":"Sales promotion - Consultants"},
            {"title":"Salt - Mfg. & Whol"},
            {"title":"Sampling"},
            {"title":"Sand & gravel"},
            {"title":"Sandwich panels"},
            {"title":"Sanitary articles - Installation & Repair"},
            {"title":"Sanitary articles - Mfg. & Whol."},
            {"title":"Sanitary units & cabins"},
            {"title":"Sanitation service"},
            {"title":"Saunas"},
            {"title":"Saunas - Installations"},
            {"title":"Savings-banks"},
            {"title":"Sawdust & shavings"},
            {"title":"Scaffolding"},
            {"title":"Scales"},
            {"title":"Scenery (Stage-, film-, & television)"},
            {"title":"School supplies"},
            {"title":"Scientific instruments & apparatus"},
            {"title":"Score-boards & luminous displays"},
            {"title":"Scouts equipment"},
            {"title":"Scrap metals"},
            {"title":"Screw Cutting"},
            {"title":"Sculptors"},
            {"title":"Seals"},
            {"title":"Secretarial services for firms"},
            {"title":"Sections & bars"},
            {"title":"Sections for doors & windows"},
            {"title":"Security consultants"},
            {"title":"Security guards"},
            {"title":"Seeds - Retail"},
            {"title":"Seminars (Organisation of)"},
            {"title":"Septic tanks"},
            {"title":"Serigraphy - Machines & Supplies"},
            {"title":"Service cheques (Services by)"},
            {"title":"Service residences"},
            {"title":"Service stations"},
            {"title":"Service stations - Installations & equipments"},
            {"title":"Sewing machines - Mfg. & Whol."},
            {"title":"Sewing machines - Retail & Repair"},
            {"title":"Sharpening service"},
            {"title":"Sheet metal"},
            {"title":"Sheet metal work"},
            {"title":"Sheet metal working machinery"},
            {"title":"Sheltered workshops"},
            {"title":"Ship - Electrical service"},
            {"title":"Ship - Repair & Mainten."},
            {"title":"Ship brokers"},
            {"title":"Ship chandlers"},
            {"title":"Ship chartering"},
            {"title":"Ship equipment & supplies"},
            {"title":"Ship owners"},
            {"title":"Ship yards"},
            {"title":"Shipping - Rhine & coastal navigation companies"},
            {"title":"Shipping - Towing & salvage services"},
            {"title":"Shipping agencies"},
            {"title":"Shirts - Mfg. & Whol."},
            {"title":"Shirts - Retail"},
            {"title":"Shoemakers"},
            {"title":"Shoemakers - Machines & Supplies"},
            {"title":"Shoes & slippers - Maintenance products & Access."},
            {"title":"Shoes & slippers - Mfg. & Whol."},
            {"title":"Shoes & slippers - Retail"},
            {"title":"Shop-window dummies"},
            {"title":"Shopping-centres"},
            {"title":"Shores & trestles"},
            {"title":"Shutters"},
            {"title":"Sieving machines & installations"},
            {"title":"Sign-boards"},
            {"title":"Signs - Illuminated"},
            {"title":"Silo Mfrs"},
            {"title":"Silverware - Retail"},
            {"title":"Sinks"},
            {"title":"Skating rinks"},
            {"title":"Ski & snowboard equipment"},
            {"title":"Ski - Rinks & schools"},
            {"title":"Slate"},
            {"title":"Slate roofing"},
            {"title":"Slaughterers"},
            {"title":"Slimming salons"},
            {"title":"Smokers' supplies"},
            {"title":"Snack-bars"},
            {"title":"Soaps & detergents - Mfg. & Whol."},
            {"title":"Social legislation - Consultants"},
            {"title":"Social service organizations"},
            {"title":"Software"},
            {"title":"Soil analysis"},
            {"title":"Soil drilling machines & equipment"},
            {"title":"Soil remediation & protection"},
            {"title":"Solar energy - Systems & apparatus"},
            {"title":"Solar protection - Inside"},
            {"title":"Solar protection - Outside"},
            {"title":"Solariums"},
            {"title":"Soldering & welding - Equipment & Supplies"},
            {"title":"Sorting machines"},
            {"title":"Sound Damping"},
            {"title":"Sound recording studios"},
            {"title":"Sound recording studios - Installation & material"},
            {"title":"Sound systems & equipment - Mfg. & Whol"},
            {"title":"Sound systems & equipment - Rental"},
            {"title":"Sound systems & equipment - Retail"},
            {"title":"Soup"},
            {"title":"Souvenirs"},
            {"title":"Speech therapists"},
            {"title":"Speed regulators & reducers"},
            {"title":"Spices & condiments"},
            {"title":"Spinning & weaving mills"},
            {"title":"Sports clubs & sports centres"},
            {"title":"Sports federations"},
            {"title":"Sports food"},
            {"title":"Sports goods - Mfg. & Whol."},
            {"title":"Sports goods - Retail"},
            {"title":"Sports shoes"},
            {"title":"Sports-grounds - Laying & Access."},
            {"title":"Sports-grounds - Lighting"},
            {"title":"Sports-hall construction & equipment"},
            {"title":"Sportscare products"},
            {"title":"Sportswear"},
            {"title":"Spray painting"},
            {"title":"Spraying equipment"},
            {"title":"Springs"},
            {"title":"Stables construction & installation"},
            {"title":"Stairs"},
            {"title":"Stamps"},
            {"title":"Stamps - Dating & name"},
            {"title":"Stapling machines & supplies"},
            {"title":"Stationery"},
            {"title":"Steel - Special & high-quality"},
            {"title":"Steel - Stainless"},
            {"title":"Steel products - Mfg. & Whol."},
            {"title":"Stickers & transfers"},
            {"title":"Stock exchanges"},
            {"title":"Stockings & socks - Mfg. & Whol."},
            {"title":"Stone (Artificial)"},
            {"title":"Stone cutters"},
            {"title":"Stone working machinery & tools"},
            {"title":"Storage of dangerous materials"},
            {"title":"Store fixtures"},
            {"title":"Stoves - Mfg. & Whol"},
            {"title":"Stoves - Retail & Repair"},
            {"title":"Strapping - Machines & material"},
            {"title":"Street cleaning & maintenance - Machines & services"},
            {"title":"Stucco contractors"},
            {"title":"Stud farms"},
            {"title":"Studios - Photo, film, & video - Rental"},
            {"title":"Subcontracting - Industrial & technical"},
            {"title":"Sugar - Mfg., Whol. & brokers"},
            {"title":"Sugar - Refineries"},
            {"title":"Suitcases - Mfg. & Whol."},
            {"title":"Sunapparatus & accessories - Mfg. & Whol."},
            {"title":"Sunapparatus & accessories - Sale & hiring"},
            {"title":"Supermarkets & Department stores"},
            {"title":"Surface treatment"},
            {"title":"Surface treatment of metals - Machines & installations"},
            {"title":"Surgery installations"},
            {"title":"Surplus & salvage goods"},
            {"title":"Surveillance - Apparatus & systems"},
            {"title":"Surveyors (Land) - Equipment"},
            {"title":"Surveyors - Building"},
            {"title":"Swimming pools"},
            {"title":"Swimming pools - Contractors & equipment"},
            {"title":"Swimming pools - Covers"},
            {"title":"Swing-doors"},
            {"title":"Synthetic fibres - Spinning & weaving mills"},
            {"title":"Tableware - Rental"},
            {"title":"Tackles"},
            {"title":"Tail-lifts for trucks"},
            {"title":"Tailoring - Jobbing"},
            {"title":"Tailors & tailoresses - Ladies' clothing"},
            {"title":"Tailors - Men's clothing"},
            {"title":"Take away food"},
            {"title":"Tank control"},
            {"title":"Tankcleaning"},
            {"title":"Tanks"},
            {"title":"Tanners"},
            {"title":"Tape - Adhesive"},
            {"title":"Tapestries"},
            {"title":"Tarpaulins"},
            {"title":"Tattooists"},
            {"title":"Taverns"},
            {"title":"Tax free shops"},
            {"title":"Tax-Consultants"},
            {"title":"Taxi - Delivery vans"},
            {"title":"Taxicabs"},
            {"title":"Taxidermists"},
            {"title":"Tea - Retail"},
            {"title":"Tea Rooms"},
            {"title":"Teaching materials"},
            {"title":"Technical textiles"},
            {"title":"Telecommunication services"},
            {"title":"Telecommunication systems"},
            {"title":"Telecopiers & supplies"},
            {"title":"Telemarketing"},
            {"title":"Telephone answering services"},
            {"title":"Telephone apparatus & accessories"},
            {"title":"Telephone installations"},
            {"title":"Television & radio - Broadcasting companies"},
            {"title":"Television & radio - Maintenance & Repair"},
            {"title":"Television & radio - Parts"},
            {"title":"Television & radio - Rental"},
            {"title":"Television & radio - Retail"},
            {"title":"Television & radio - Transmitting equipment & material"},
            {"title":"Television & radio broadcasting services"},
            {"title":"Temping agencies - Clerical"},
            {"title":"Temping agencies - Manual"},
            {"title":"Tennis & supplies"},
            {"title":"Tennis courts - Construction"},
            {"title":"Tents (Halls) - Mfg. & Hire"},
            {"title":"Terraces (Horeca)"},
            {"title":"Textile - Printing"},
            {"title":"Textile - Raw materials"},
            {"title":"Textile finishing"},
            {"title":"Textile labels"},
            {"title":"Textile machinery"},
            {"title":"Textiles - Mfg. & Whol"},
            {"title":"Textiles - Retail"},
            {"title":"Theatre groups"},
            {"title":"Theatres & concert-halls"},
            {"title":"Theatrical agencies"},
            {"title":"Theatrical equipment & supplies"},
            {"title":"Tickets"},
            {"title":"Ties & scarves"},
            {"title":"Tiles"},
            {"title":"Timber - Retail"},
            {"title":"Timber - Whol."},
            {"title":"Time recorders"},
            {"title":"Tin"},
            {"title":"Tinning"},
            {"title":"Tobacco - Leaf"},
            {"title":"Tombolas (Organisation of)"},
            {"title":"Tools (Electric)"},
            {"title":"Tools - General"},
            {"title":"Tools Mfg."},
            {"title":"Tourism - Offices & federations"},
            {"title":"Tourism - Sights of interest"},
            {"title":"Toys - Mfg. & Whol."},
            {"title":"Toys - Retail"},
            {"title":"Tractors (Agricultural)"},
            {"title":"Trade & businesses - Hand-over & take-over"},
            {"title":"Trailer couplings"},
            {"title":"Trailers & semi-trailers - Industrial"},
            {"title":"Trailers for passenger cars"},
            {"title":"Trampolines - Sales & Rental"},
            {"title":"Translators"},
            {"title":"Transmissions"},
            {"title":"Transport - Art goods"},
            {"title":"Transport - Automobiles"},
            {"title":"Transport - Bulk goods"},
            {"title":"Transport - Combined"},
            {"title":"Transport - Containers (Freight)"},
            {"title":"Transport - Dump trucks"},
            {"title":"Transport - General"},
            {"title":"Transport - International"},
            {"title":"Transport - Livestock"},
            {"title":"Transport - Machines & indivisible parts"},
            {"title":"Transport - Pianos"},
            {"title":"Transport - Plants"},
            {"title":"Transport - Refrigerated"},
            {"title":"Transport - Tankers"},
            {"title":"Transport of dangerous materials"},
            {"title":"Travel agencies"},
            {"title":"Travel goods"},
            {"title":"Travel insurance"},
            {"title":"Travelling cranes"},
            {"title":"Tree work - Lopping & felling"},
            {"title":"Truck-body repair"},
            {"title":"Trucks & delivery vans - Parts & Access."},
            {"title":"Trucks & delivery vans - Used"},
            {"title":"Trucks & vans - Hire"},
            {"title":"Trucks - Concessionaires"},
            {"title":"Trucks - Washing"},
            {"title":"Truss-makers"},
            {"title":"Tubing (Industrial)"},
            {"title":"Turf growers"},
            {"title":"Tyres"},
            {"title":"Tyres - Recapping & retreading"},
            {"title":"Umbrellas & parasols"},
            {"title":"Underwater works"},
            {"title":"Underwear - Mfg. & Whol."},
            {"title":"Uniforms"},
            {"title":"Upholsterers"},
            {"title":"Upholstery fabrics - Mfg. & Whol."},
            {"title":"Upholstery fabrics - Retail"},
            {"title":"Upholstery parts"},
            {"title":"Vacation centres"},
            {"title":"Vacuum cleaners (Domestic)"},
            {"title":"Vacuum cleaners (Industrial)"},
            {"title":"Vacuum machinery & equipment"},
            {"title":"Valuers - Art"},
            {"title":"Valuers - General"},
            {"title":"Vegetables - Early season"},
            {"title":"Vending machines"},
            {"title":"Veneers"},
            {"title":"Ventilating systems"},
            {"title":"Verandas"},
            {"title":"Vet Center"},
            {"title":"Veterinarians"},
            {"title":"Veterinarians - Instruments & Supplies"},
            {"title":"Vibrationdampers"},
            {"title":"Vibrators - Industrial"},
            {"title":"Video - Equipment & Supplies - Sale"},
            {"title":"Video - Equipment - Hiring"},
            {"title":"Video - Production & realisation"},
            {"title":"Video - Professional material"},
            {"title":"Video - Tapes & cassettes (Pre-recorded) - Mfg. & Whol."},
            {"title":"Video films - Hiring"},
            {"title":"Video reports"},
            {"title":"Video-Equipment - Mainten. & Repair"},
            {"title":"Videoconferencing systems"},
            {"title":"Videotapes - Duplication"},
            {"title":"Vinegar & mustard - Mfg. & Whol"},
            {"title":"Voice technology - Products & systems"},
            {"title":"Wafers & pancakes - Mfg."},
            {"title":"Wall coverings"},
            {"title":"Wall cupboards"},
            {"title":"Wall paintings"},
            {"title":"Wallpaper - Mfg. & Whol."},
            {"title":"Wallpaper - Retail"},
            {"title":"Warehouses - Cold storage"},
            {"title":"Warehouses - Liquids"},
            {"title":"Warehouses - Merchandise"},
            {"title":"Washing machines & automatic laundry driers - Mfg. & Whol."},
            {"title":"Washing machines & automatic laundry driers - Retail, Mainten. & Repair"},
            {"title":"Waste management"},
            {"title":"Waste paper"},
            {"title":"Waste treatment - Machines & installations"},
            {"title":"Watches & clocks - Mfg. & Whol."},
            {"title":"Watches & clocks - Retail, Repair & Mainten."},
            {"title":"Watches & clocks - Supplies"},
            {"title":"Water distribution"},
            {"title":"Water heaters"},
            {"title":"Water purification"},
            {"title":"Water softeners"},
            {"title":"Water sports articles"},
            {"title":"Water treatment"},
            {"title":"Water treatment products"},
            {"title":"Waterproofing materials"},
            {"title":"Waterproofing works"},
            {"title":"Waters & soft drinks"},
            {"title":"Weaving machines & looms"},
            {"title":"Wedding presents - Lists"},
            {"title":"Weighbridges"},
            {"title":"Welding"},
            {"title":"Wells boring"},
            {"title":"Wheelbarrows - Mfg. & Whol."},
            {"title":"Wheels & rolls"},
            {"title":"Whirlpools"},
            {"title":"Wickerwork, cane & bamboo"},
            {"title":"Wigs & hairworks"},
            {"title":"Window cleaners"},
            {"title":"Window display supplies"},
            {"title":"Windows"},
            {"title":"Windsurfing & accessories"},
            {"title":"Wine cellar fixtures"},
            {"title":"Wine- & beermaking equipment & accessories"},
            {"title":"Wines & spirits - Mfg & Whol."},
            {"title":"Wines & spirits - Retail"},
            {"title":"Wire - Netting, metal, cloth & screens"},
            {"title":"Wood - Import"},
            {"title":"Wood fiber & wood chip boards"},
            {"title":"Wood planing & sawing"},
            {"title":"Wood preservation"},
            {"title":"Wood turning & carving"},
            {"title":"Woodenware"},
            {"title":"Woodworking machinery & equipment"},
            {"title":"Wool - Whol. & brokers"},
            {"title":"Wordprocessing - Service offices"},
            {"title":"Work platforms"},
            {"title":"Workbenches"},
            {"title":"Works at dangerous heights"},
            {"title":"Works of art - Restoration"},
            {"title":"Writers"},
            {"title":"Yachts & pleasure boats - Builders"},
            {"title":"Yachts & pleasure boats - Rental"},
            {"title":"Yachts, pleasure boats & accessories - Sales"},
            {"title":"Yeast"},
            {"title":"Yoga"},
            {"title":"Youth clubs & youth centres"},
            {"title":"Zinc plating"},
            {"title":"Zippers"},
            {"title":"Zoo"},
    ]
export default text
