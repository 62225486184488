import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Layout} from "../../components/Layout";
import axios from 'axios'
import {useDispatch, useSelector} from "react-redux";
import {getProfileEmail, getProfile} from "../../redux/profile/profileActions";
import data from "../../lawyers.json"

const Profile = () => {
    const {loginWithRedirect, user, isAuthenticated, getAccessTokenSilently} = useAuth0();
    const [userMetadata, setUserMetadata] = useState(null);

    const dispatch = useDispatch()
    dispatch(getProfileEmail(user.email))
    // const [userData, setUserData] = useState([])
    // console.log(userData)
    // let [itemBuy, setItemBuy] = useState([])
    // // let itemBuy = []
    // console.log(itemBuy)

    async function getMyData() {
        try {
            let response = await axios.get(`http://localhost:7000/api/success-payment/${user.email}`,)
            // .then(function (response) {
            //     // handle success
            //     // console.log(JSON.stringify(response.data.id));
            //     console.log(response );
            // })
            // .catch(function (error) {
            //     // handle error
            //     console.log(error);
            // })
            // .then(function () {
            //     // always executed
            // });
            // console.log(response.data);
            // response.data.map(i => {
            //     // setUserData(userData => [userData, i.cost])
            //     setUserData(userData => [userData, i.cost])
            //     // userData.push(i)
            //     console.log(i.cost)
            //     console.log(i.uuid_cost)
            //     console.log(i.categories)
            //     // setItemBuy(...itemBuy, {cost: i.cost, uuid_cost: i.uuid_cost, categories: i.categories})
            //     // dispatch(getProfile({cost: i.cost, uuid_cost: i.uuid_cost, categories: i.categories}))
            // })
            dispatch(getProfile(response.data))

        } catch (e) {
            console.error(e)
        }

    }

    useEffect(() => {
        getMyData()
    }, [])
    // console.log(userData.length)
    const profile = useSelector((state) => state.profile.item_buy)
    console.log(profile)
    let temp = 0

    profile.map(c => {
        temp += c.cost
        console.log(typeof c.cost)
        console.log('temp', temp)
        return temp
    })
    let website = 0
    data.map(d => {
        if (d.website !== null){
            website+=1
            return website
        }
    })
    return (
        isAuthenticated && (
            <Layout border>
                <div className="profile_page-wrap" style={{display: 'flex', justifyContent: 'space-around'}}>
                    <div className="page_row">
                        <div className="page_col">
                            <img src={user.picture} alt={user.name}/>
                            <h2>{user.name}</h2>
                            <p><span>nickname - </span> {user.nickname}</p>
                            <p>{user.email}</p>
                            <h3>User Metadata</h3>
                            {isAuthenticated ? (
                                <>
                                    {/*<pre>{JSON.stringify(user, null, 2)}</pre>*/}
                                </>
                            ) : (
                                "No user metadata defined"
                            )}
                        </div>
                    </div>

                    <div className="page_row">
                        <div className="page_col">
                            {/*<button onClick={() => getMyData()} style={{color: 'black'}}>my data</button>*/}
                            {profile.length > 0
                                ?
                                <>
                                    <p>website: {website}</p>
                                    <div style={{width:300}}>
                                        {    data.map(d => {
                                            if (d.website !== null){
                                                return (<>
                                                        <span>{d.website}</span><br/>
                                                    </>
                                                )
                                            }
                                        })}
                                    </div>

                                    <h3>Your DB:</h3>
                                    {profile.map(d => (
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            border: 'solid 1px #fff',
                                            marginTop: 20,
                                            padding: 10,
                                            background: '#404040',
                                            color: '#dddddd'
                                        }}>
                                            {/*<span>{d.uuid_cost}</span><br/>*/}
                                            <span>{d.cost} €</span><br/>
                                            <span style={{color: '#fff'}}>Categories: </span>
                                            {/*<hr style={{width: '100%'}}/>*/}
                                            {d.categories.map(c => (
                                                <>
                                                    <span>{c}</span>
                                                    {/*<hr style={{width: '100%'}}/>*/}
                                                </>
                                            ))}
                                        </div>
                                    ))}
                                    <p style={{marginTop: 30}}>All cost:</p>
                                    <p>{temp}</p>
                                </>
                                :
                                <p>Your DB is NULL</p>
                            }


                            {/*<h3>User Metadata</h3>*/}
                            {/*{isAuthenticated ? (*/}
                            {/*    // <pre>{JSON.stringify(user, null, 2)}</pre>*/}
                            {/*    <pre>{JSON.stringify(user, null, 2)}</pre>*/}
                            {/*) : (*/}
                            {/*    "No user metadata defined"*/}
                            {/*)}*/}
                        </div>
                    </div>
                </div>

            </Layout>
        )
    );
};

export default Profile;