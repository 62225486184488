import React, {useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {Layout} from "../../components/Layout";

const SomeComponent = React.memo(({list}) => {
    console.log('Render SomeComponent')
    return (
        <div>
            {list.map(item => <div key={item}>{item}</div>)}
        </div>
    )
})

const getList = () => [1,2,3]
const Test = (props) => {
    const {loginWithRedirect,user, isAuthenticated, getAccessTokenSilently} = useAuth0();

    const [count, setCount] = useState(0)
    // const [list, setList] = useState([1,2,4])

    const list = React.useMemo(() => getList(),[])
    console.log('Render Test')

    const increment = () => setCount(count + 1)
    return (
        isAuthenticated && (
            <Layout border>
                <div className="page_row">
                    <h1>{count}</h1>

                    <button onClick={increment} style={{color: 'black'}}>increment</button>
                    <SomeComponent list={list}/>
                </div>
            </Layout>
        )
    );
};

export default Test;