import React, {useEffect} from 'react';
import {Switch} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {falseEmail, setEmail} from "../../redux/email/emailActions";
import {useLocation} from "react-router-dom";

const SwitchEmail = () => {
    // const email = useSelector((state => state.email));
    const dispatch = useDispatch()

    function onChange(checked) {
        dispatch(setEmail(checked))
    }
    const location = useLocation()
    useEffect(() => {
        if (location.path !== '/'){
            dispatch(falseEmail())
        }
    }, [location.path])
    return (
        <div className="switch_box">
            <label>
                <Switch
                    defaultChecked={false}
                    onChange={onChange}
                />
                Email
            </label>
        </div>
    );
};

export default SwitchEmail;