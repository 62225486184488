import {SET_ADDRESS, FALSE_ADDRESS} from "./type";

const initialState = {
	addressActive: false
}
const addressReducer = (state = initialState, action) => {

	switch (action.type) {
		case SET_ADDRESS:
			return {
				...state,
				addressActive: action.payload
			}
		case FALSE_ADDRESS:
			return {
				...state,
				addressActive: false
			}
		default:
			return state;
	}
};

export default addressReducer;
