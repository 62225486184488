import React from "react";
import {Sidebar} from "./sidebar";
import HeaderBottom from "./header-bottom";
import Logic from "./header-bottom/Logic";
import SidebarInner from "./sidebar-inner";

export const Layout = ({children, border}) => {
    const {toggleSidebarActive} = Logic();
    return (
        <div className="page">
            <div className="container">
                <Sidebar/>
                <div className="page_content">
                    {children}
                </div>
                <SidebarInner/>
                <div onClick={toggleSidebarActive} className="sidebar_overlay"></div>
                <HeaderBottom/>
            </div>

        </div>
    );
};
