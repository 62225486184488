import { useSelector, useDispatch } from "react-redux";
import { themeDark, themeLight } from "../../redux/theme/themeAction";

const Logic = () => {
	const dispatch = useDispatch();
	const toggle = useSelector((s) => s.theme.isDark);

	const toggleDarkTheme = () => {
		if (!toggle) {
			document.body.classList.add("dark");
			dispatch(themeDark());
		} else {
			document.body.classList.remove("dark");
			dispatch(themeLight());
		}
	};

	const toggleSidebarActive = () => {
		const side = document.querySelector(".sidebar_inner");
		side.classList.toggle("active");
		const overlay = document.querySelector(".sidebar_overlay")
		overlay.classList.toggle("visible")
		console.log('close')
	};

	return {
		toggle,
		toggleDarkTheme,
		toggleSidebarActive
	};
};

export default Logic;
