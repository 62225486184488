import {SET_WEBSITE, FALSE_WEBSITE} from "./type";

export const setWebsite = (payload) => {
    return {
        type: SET_WEBSITE,
        payload: payload
    };
};
export const falseWebsite = () => {
    return {
        type: FALSE_WEBSITE,
        payload: false
    };
};
