import "./sass/main.scss";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
    <Provider store={store}>
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={window.location.origin}
            audience="https://dev-l8pn455p.us.auth0.com/api/v2/"
            scope="read:current_user update:current_user_metadata"
        >
            <Router>
                <App />
            </Router>
        </Auth0Provider>
    </Provider>,
    document.getElementById("root")
);
