import React, {useEffect} from 'react';
import {Switch} from 'antd';
import {useDispatch} from "react-redux";
import {falsePhone, setPhone} from "../../redux/phone/phoneActions";
import {useLocation} from "react-router-dom";

const SwitchPhone = () => {

    const dispatch = useDispatch()

    function onChange(checked) {
        dispatch(setPhone(checked))
    }
    const location = useLocation()
    useEffect(() => {
        if (location.path !== '/'){
            dispatch(falsePhone())
        }
    }, [location.path])


    return (
        <div className="switch_box">
            <label>
                <Switch
                    defaultChecked={false}
                    onChange={onChange}
                />
                Phone
            </label>
        </div>
    );
};

export default SwitchPhone;
