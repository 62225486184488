import * as api from '../../api/index'
import {
    PRODUCTS_LOADING,
    GET_ALL_PRODUCTS,
    GET_ONE_REGION_PRODUCTS,
    GET_ONE_PRODUCT,
    CLEAR_PRODUCTS
} from './types'
import { returnErrors } from '../error/errorAction'


export const getAllProducts = () => async dispatch => {
    dispatch({ type: PRODUCTS_LOADING })
    await api.getAllProducts()
        .then(res => dispatch({
            type: GET_ALL_PRODUCTS,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.message)))
}

export const getOneRegionProducts = () => async dispatch => {
    dispatch({ type: PRODUCTS_LOADING })
    await api.getOneRegionProducts()
        .then(res => dispatch({
            type: GET_ONE_REGION_PRODUCTS,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.message)))
}

export const getOneRegionProduct = (r, c) => async dispatch => {
    dispatch({ type: PRODUCTS_LOADING })
    await api.getOneRegionProduct(r, c)
        .then(res => dispatch({
            type: GET_ONE_PRODUCT,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.message)))
}

export const clearProducts = () => dispatch => {
    dispatch({
        type: CLEAR_PRODUCTS
    })
}

// export const addProduct = newProduct => async dispatch => {
//     await api.addProduct(newProduct)
//         .then(res => dispatch({
//             type: ADD_PRODUCT,
//             payload: res.data
//         }))
//         .catch(err => {
//             dispatch(returnErrors(err.message))
//             alert(err.message)
//         })
// }
//
// export const editProduct = product => dispatch => {
//     dispatch({
//         type: EDIT_PRODUCT,
//         payload: product
//     })
// }
//
// export const cancelEdit = () => dispatch => {
//     dispatch({
//         type: CANCEL_EDIT
//     })
// }
//
// export const updateProduct = (updatedProduct) => async dispatch => {
//     await api.updateProduct(updatedProduct)
//         .then(res => dispatch({
//                 type: UPDATED_PRODUCT,
//                 payload: res.data
//             })
//         )
//         .catch(err => dispatch(returnErrors(err.message)))
// }
//
// export const deleteProduct = (id) => async dispatch => {
//     await api.deleteProduct(id)
//         .then(res => dispatch({
//             type: DELETE_PRODUCTS,
//             payload: res.data
//         }))
//         .catch(err => dispatch(returnErrors(err.message)))
// }