import {
    SET_RESULT_WEBSITE,
    SET_RESULT_EMAIL,
    SET_RESULT_PHONE,
    SET_RESULT_ADDRESS,
    SET_RESULT
} from "./type";


export const setResultAddress = (addressResult) => {
    return {
        type: SET_RESULT_ADDRESS,
        addressResult: {addressResult},
    };
};

export const setResultPhone = (phoneResult) => {
    return {
        type: SET_RESULT_PHONE,
        phoneResult: {phoneResult},
    };
};

export const setResultEmail = (emailResult) => {
    return {
        type: SET_RESULT_EMAIL,
        emailResult: {emailResult},
    };
};

export const setResultWebsite = (websiteResult) => {
    return {
        type: SET_RESULT_WEBSITE,
        websiteResult: {websiteResult},
    };
};

export const setResult = (setResult) => {
    return {
        type: SET_RESULT,
        setResult: {setResult},
    };
};