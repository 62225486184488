import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { NavLink, useLocation } from 'react-router-dom'
import { AiOutlineLogout } from 'react-icons/ai/index'
import { SwitchTheme } from '../sidebar/SwitchTheme'
import { CloseOutlined } from '@ant-design/icons'
import getWindowDimensions from '../getWindowDimensions'
const SidebarInner = () => {
  const { width } = getWindowDimensions()
  // console.log(width)
  const toggleSidebarActive = () => {
    const side = document.querySelector('.sidebar_inner')
    side.classList.toggle('active')
    const overlay = document.querySelector('.sidebar_overlay')
    overlay.classList.toggle('visible')
    // console.log('close')
  }
  const activeSidebarInner = document.querySelector('.sidebar_inner.active')
  if (activeSidebarInner && width > 500) {
    // console.log(width)
    toggleSidebarActive()
  }

  const location = useLocation()
  const { logout, loginWithRedirect, user, isAuthenticated, getAccessTokenSilently } = useAuth0()

  return (
    isAuthenticated && (
      <div className='sidebar_inner'>
        <div className='sidebar_inner-head'>
          <div className='sidebar_inner-person'>
            <img src={user.picture} alt={user.name} />
            <span>{user.name}</span>
          </div>
          {/*<ButtonClose/>*/}
          <CloseOutlined onClick={toggleSidebarActive} />
        </div>

        <SwitchTheme />

        <div className='sidebar_inner-logout'>
          <NavLink
            onClick={() =>
              logout({
                returnTo: window.location.origin,
              })
            }
            to='login'
            className={location.pathname === '/login' ? 'sidebar_item active_link' : 'sidebar_item'}
            key='login'>
            <div className='sidebar_icon'>
              <AiOutlineLogout className='icon' />
            </div>
            <span className='sidebar_text-logout'>Log Out</span>
          </NavLink>
        </div>
      </div>
    )
  )
}

export default SidebarInner
